import { FileItem } from "./file-item";
import { FloorEquipment } from "./floor-equipments";

export class Floor {
    id: any = 0;
    name?: string;
    height?: string;
    width?: string;
    fileName?: string;
    fileLocation?: string;
    leftHours?= 0;
    isArchived?: boolean;
    isDeleted?: boolean;
    isNewFile?: boolean;
    fileData?: FileItem;
    installedDeviceCount?: number;
    InstalledDevice?= 0;
    inProgressDevice?: number;
    planneddDevice?: number;
    oldFloor?: boolean;
    isFloorSelected?: any;
    selectedFloor?: any;
    percentageInstalledDevices?= 0;
    totalDevices?= 0
    installedDevices?= 0
    awsImageURL?: string;
    thumbnailAwsImageURL?: string;
    orderNumber?: any;
    floorEquipments?: FloorEquipment[];
    serviceTicket?: any[];
    isActive?: boolean;
    isSelected?: boolean;
    awsImageExist?: boolean;
    isUpdateFromEditor?: boolean;
    isCanEdit?: boolean;
    associatedProject?: [];
    baseImageURL?: string;
    isfloorImageEdited?: boolean;
    canResizePlanMarkup?: boolean;
    isUpdate?: boolean;
    modifiedDateTime?: any;
    updatedByUser?: any;
    isTechnicianView?: any;
    isLiveDeviceAvailable?: any;
    floorScale?:any;
    isImageExsits?: any;
    isImageLoading?: any;
    imageCheckCnt?: any;
    installationNotes?:any;
    instructions?:any;
    constructor() {
        this.name = '';
        this.fileLocation = '';
        this.fileName = '';
        this.awsImageURL = '';
        this.isDeleted = false;
        this.floorEquipments = [];
        this.serviceTicket = [];
    }
}
