/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { CustomerAPIPath, Environment, PreSignedUrl, SiteAPIPath, UserAPIPath } from '../constants';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Utilities } from '../utilities';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private readonly env: Environment, private http: HttpClient, private ajaxService: AjaxService, private commonService: CommonService, private authService: AuthService) { }
  getSiteList(): any {
    return this.ajaxService.get(SiteAPIPath.list);
  }
  getSiteFloorImages(id: any, isProject?: any, buildingId?: any): any {
    if (!isProject) {
      return this.ajaxService.get(`${SiteAPIPath.site}/floorthumbnails?${isProject ? 'projectId' : 'siteId'}=${id}`);
    } else {
      return this.ajaxService.get(`${SiteAPIPath.site}/floorthumbnails?${isProject ? 'projectId' : 'siteId'}=${id}&buildingId=${buildingId}`);
    }
  }
  getAssignedSites(isImage?: boolean) {
    if (isImage) {
      return this.ajaxService.get(SiteAPIPath.assginedSites + '?isThumbImage=true');
    } else {
      return this.ajaxService.get(SiteAPIPath.assginedSites);
    }
  }

  uploadCustomerImage(images: any) {
    return this.ajaxService.post(`${SiteAPIPath.image}`, images);
  }

  getAssignedSitesUser(id: any) {
    return this.ajaxService.get(SiteAPIPath.site + '/' + id + '/assignees');
  }

  deleteResources(data: any, isFromProfile?: any) {
    const url = isFromProfile ? UserAPIPath.user + `/${data.userId}/site/${data.siteId}/permission` : SiteAPIPath.site + `/${data.siteId}/user/` + `${data.userId}` + '/permission';
    return this.ajaxService.delete(url, {});
  }
  getSiteDetail(id: number, page?: any): any {
    if (page) {
      return this.ajaxService.get(SiteAPIPath.siteDetailsForLibrary + '/' + id);
    } else {
      return this.ajaxService.get(SiteAPIPath.siteDetails + '/' + id);
    }
  }
  updateSite(site: any): any {
    return this.ajaxService.put(SiteAPIPath.site, site);
  }

  addSite(site: any): any {
    return this.ajaxService.post(SiteAPIPath.site, site);
  }

  deleteSite(id: number): any {
    return this.ajaxService.delete(SiteAPIPath.site + '/' + id);
  }
  getDomotzAgents(): any {
    return this.ajaxService.get(`${SiteAPIPath.domotzAgent}`);
  }
  archiveUnarchiveSite(id: number, isArchived: any): any {
    return this.ajaxService.get(SiteAPIPath.site + '/' + id + '/archived?isArchived=' + isArchived);
  }
  getSwtichConnection(id: any, buildingId: any, floorId: any) {
    return this.ajaxService.get(`${SiteAPIPath.switchFloors}?siteId=${id}&buildingId=${buildingId}&floorId=${floorId}`);
  }

  getResources(id: any) {
    return this.ajaxService.get(SiteAPIPath.site + '/' + id + '/permission');
  }
  getUsers() {
    return this.ajaxService.get(`${SiteAPIPath.users}/users`);
  }

  addResources(data: any) {
    return this.ajaxService.post(SiteAPIPath.site + '/resources', data);
  }

  moveAndReorder(data: any) {
    return this.ajaxService.post(`${SiteAPIPath.moveAndReorder}`, data);
  }

  addBuildings(buildings: any, id: any) {
    return this.ajaxService.post(`${SiteAPIPath.site}/${id}/buildings`, buildings);
  }

  updateBuilding(siteId: number, building: any): any {
    return this.ajaxService.put(`${SiteAPIPath.site}/${siteId}/building`, building);
  }
  uploadFile(url: string, formData: any) {
    let uploadContentType = 'multipart/form-data';
    if (this.env.localstack) {
      uploadContentType = 'undefined';
    }
    return this.ajaxService.put(url, formData, { headers: { 'Content-Type': uploadContentType } }, { removeAuthToken: true });
  }

  getPresignedUrl(reqData: any): any {
    return this.ajaxService.get(`${PreSignedUrl + Utilities.objectToParams(reqData)}`);
  }

  getBuilding(id: number): any {
    return this.ajaxService.get(SiteAPIPath.building + '/' + id);
  }
  addBuilding(siteId: number, building: any): any {
    return this.ajaxService.post(`${SiteAPIPath.site}/${siteId}/building`, building);
  }

  uploadPdfFile(siteId: number, buildingId = 0, formData: any): any {
    return this.ajaxService.post(`${SiteAPIPath.site}/${siteId}/building/${buildingId}/floor/uploadFile`, formData);
  }

  moveToProject(buildingId: any, projectId: any) {
    return this.ajaxService.post(`${SiteAPIPath.building}/${buildingId}/migrate/${projectId}`, null);
  }

  updateSiteDetail(id: any, name: any) {
    return this.ajaxService.patch(`${SiteAPIPath.site}/${id}?sitename=${name}`, {});
  }

  getFiles(siteId: any) {
    return this.ajaxService.get(`${SiteAPIPath.site}/${siteId}/file`);
  }

  getSiteFilePresignedURL(reqData: any) {
    return this.ajaxService.get(SiteAPIPath.presigned + Utilities.objectToParams(reqData));
  }

  saveSiteFiles(files: any) {
    return this.ajaxService.post(`${SiteAPIPath.saveFiles}`, files);
  }

  deleteFileFromSite(siteId: any, fileId: any) {
    return this.ajaxService.delete(`${SiteAPIPath.site}/${siteId}/file/${fileId}`);
  }

  getAllSiteList(): any {
    return this.ajaxService.get(SiteAPIPath.allSiteList);
  }

  getSiteDetailsFromLib(siteId: any): any {
    return this.ajaxService.get(`${SiteAPIPath.siteDetailsFromLib}/${siteId}`);
  }
  getProjectsForMigrate(customerId: any): any {
    return this.ajaxService.get(`${CustomerAPIPath.customer}/${customerId}/projects/skinny`);
  }
}
