import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CommonListAPIPath } from '../constants/api.constant';
import { AjaxService } from './ajax.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  defaultDeviceFloorStatus!: any;

  constructor(private ajaxService: AjaxService) { }
  getStatusData() {
    if (this.defaultDeviceFloorStatus === undefined) {
      return this.ajaxService.get(CommonListAPIPath.deviceStatus).pipe(map(r => {
        this.defaultDeviceFloorStatus = r;
        return r
      }))
    } else {
      return new Observable((observer) => {
        observer.next(this.defaultDeviceFloorStatus);
        observer.complete();
      })
    }
  }

  getProjectChartData(projectId: any, days: any): any {
    return this.ajaxService.get(`${CommonListAPIPath.projectChartData}?projectId=${projectId}&days=${days}`);
  }
}
