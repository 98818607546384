/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventService } from '@SiteOwl/core';
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss'],
})
export class CommonPopupComponent {
  header!: string;
  subHeaderContent!:string;
  btnSuccessText!: string;
  btnCancelText!: string;
  btnSuccessClass!: any;
  btnCancelClass!: any;
  btnSuccessStyle!: any;
  btnCancelStyle!: any;
  bodyClass: any;
  bodyStyle: any;
  content: any;
  dialogClass: any;
  dialogStyle: any
  dialogId: any
  type: any
  public isDisableAction = false
  public event: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef, private eventService: EventService) { }

  _action(event: any) {
    this.event.emit(this.btnSuccessText);
    this.bsModalRef.hide();
  }

  _actionCancel(event:any){
    this.event.emit(this.btnCancelText);
    this.bsModalRef.hide();
  }
  setDisable(isDisableAction = false) {
    this.isDisableAction = isDisableAction;
  }
}
