/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationNotification, AuthService, Building, ButtonEnableDisableService, DeleteFloor, Environment, EventEmitterType, EventService, FileItem, FileUploadConstant, FileUploadOptions, FileUploadService, Floor, HeaderActionType, MessageService, Roles, SiteService, Utilities, ValidationService, templateImage, templateType } from '@SiteOwl/core';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DragulaService } from 'ng2-dragula';
import { Subscription, forkJoin } from 'rxjs';
import * as _ from 'lodash';
// @ts-ignore: Unreachable code error
import autoScroll from 'dom-autoscroller';
import { TooltipModel } from '../../tool-tip/tool-tip.model';
import b64toBlob from 'b64-to-blob';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ChoosePlanPopupComponent } from '../choose-plan-popup/choose-plan-popup.component';
import { NewPlanPreviewComponent } from '../new-plan-preview/new-plan-preview.component';
import { CommonPopupComponent } from '../../common-popup/common-popup.component';
import { PixieComponent } from '../../pixie/pixie.component';


type ValidFormats = 'png' | 'jpeg' | 'json';


@Component({
  selector: 'so-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.scss'],
})
export class CreatePlanComponent implements OnInit, OnDestroy {
  sites!: any;
  buildings!: any;
  siteId!: any;
  buildingId!: any;
  isEdit!: boolean;
  siteBuildings!: any;
  PreviousModalDTO: any = {
    closeAllModal: false,
    newFloors: [],
    buildings: [],
    site: {},
    buildingId: 0
  };
  public addPlans: EventEmitter<any> = new EventEmitter();
  isDisableBack = true;
  disableNext = false;
  navigationStart!: boolean;
  routerSub!: any;
  buildingForm!: FormGroup;
  canOpenBroweserPopup = true;
  isClosed = false;
  changesSaved = false;
  uploadedFiles!: any;
  isnewFloor!: boolean;
  isNewPlan!: boolean;
  fileUploadOptions!: FileUploadOptions;
  dragulaServiceDropSubscription!: Subscription;
  body!: HTMLElement;
  quoteBody!: HTMLElement;
  buildingBody!: HTMLElement;
  buildingDetails: any;
  showSpinner!: boolean;
  addPlansCreateButtonChange!: boolean;
  defaultBuildingDetails: any;
  defObjBuilding: any;
  menu: any = {
    hideLeftMenu: false,
    title: '',
    subMenu: {
      showEditableLabel: true,
      hideTitle: true,
      editableLabel: {
        value: '',
        event: ''
      },
      hideBackButton: true,
      hideHeader: true,
      hideActionButton: false,
      title: null
    }
  }
  isFloorPlanAddedToAWS!: boolean;
  isBuildingUpdated!: boolean;
  selectedFloorIndex!: any;
  selectedFloor!: any;
  isEditMode!: boolean;
  currentBuildingIndex: any = 0;
  isBack: any;
  responseFromUpdate: boolean | undefined;
  isSiteEdit: any;
  isBackForBuildingClosePopup = false;
  isEditBuildingMode: any;
  sub!: Subscription;
  cusSub!: Subscription;
  @ViewChild('img') img: any
  @ViewChild("autoscroll", { static: true }) autoscroll: ElementRef | undefined;
  defaultFloorDataName!: any;
  defaultFloorData!: any;
  errorMessage!: any;
  tempDeletdPlans: any[] = [];
  tempFloor!: any;
  showArchivedFloor: any = true;
  timer!: any;
  toolTipObj: any;
  isUploadStart!: boolean;
  isSaveRemain!: boolean;
  newFloors: any;
  uploadedFile: any;
  NoPlanMessage = "No Plans available."
  cdnUrl = this.env.cdnUrl;
  bsModalRef!: BsModalRef;
  bsModalPlanRef!: BsModalRef;
  isFromBuilding!: any
  bsModalDelete!: BsModalRef;
  bsModalImage!: BsModalRef;
  @ViewChild('buildingfileUpload', { static: true }) buildingfileUpload!: ElementRef;
  public closeAllModal: EventEmitter<any> = new EventEmitter();
  public backToSite: EventEmitter<any> = new EventEmitter();
  public closeAll: EventEmitter<any> = new EventEmitter();
  canDeletePlan = false;
  acceptFiles: any ='.jpeg,.jpg,.png,.pdf';
  constructor(
    private readonly env: Environment,
    public btnService: ButtonEnableDisableService,
    private dragulaService: DragulaService,
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
    private fileUploadService: FileUploadService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private eventService: EventService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private modalService: BsModalService,
    private bsModelAddPlanRef: BsModalRef,
    private authService: AuthService,
  ) { }

  initCall() {
    this.PreviousModalDTO.buildings = [... this.buildings]
    this.buildings.forEach((x: any) => { x['floors'] = []; })
    if (this.buildings.length === 1) {
      this.isDisableBack = true;
      this.disableNext = true;
    }

    this.navigationStart = true;
    this.routerSub = this.router.events.forEach((event: any) => {
      if (event instanceof NavigationStart) {
        if (this.canOpenBroweserPopup && (this.buildingForm.dirty && !this.changesSaved)) {
          if (window.confirm(ApplicationNotification.pageReload)) {
            this.completeFloorFromNavigation();
            this.buildingForm.markAsPristine();
            this.uploadedFiles = null;
            this.isnewFloor = false;
            this.canOpenBroweserPopup = true;
            this.isNewPlan = false;
          } else {
            this.buildingForm.markAsPristine();
            this.uploadedFiles = null;
            this.canOpenBroweserPopup = true;
            this.closeModal();
            this.isnewFloor = false;
            this.isNewPlan = false;
          }
        }
      }
    });

    this.fileUploadOptions = {
      isMultiple: false,
      acceptExtensions: FileUploadConstant.ALLOWEDIMAGEFILES,
      maxSize: FileUploadConstant.ALLOWEDFILESIZE
    }
    this.dragulaServiceDropSubscription = this.dragulaService.drag().subscribe((value: any) => {
      this.buildingForm.markAsDirty();
      this.canOpenBroweserPopup = true;
      this.body.classList.add("drag-thumb-img")

      document.onmousemove = (e) => {
        const event = e || window.event;
        const mouseY = event['pageY'];
        const scrollTop = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop; // document.documentElement.scrollTop is undefined on the Edge browser
        const scrollBottom = scrollTop + window.innerHeight;
        let elementHeight = 0;
        if (value && value[1] && value[1].offsetHeight) {
          elementHeight = value[1].offsetHeight; // this is to get the height of the dragged element
        }
        try {
          if (mouseY - elementHeight / 2 < scrollTop) {
            window.scrollBy(0, -30);

            this.buildingBody.scrollBy(0, -30);
          } else if (mouseY + elementHeight > scrollBottom) {
            window.scrollBy(0, 30);
            this.buildingBody.scrollBy(0, 30);

          }
        } catch (e) {

        }
      };

    })
    // detach the mouse move event when the drag ends
    this.dragulaService.dragend().subscribe(() => {
      document.onmousemove = null;
      this.body.classList.remove("drag-thumb-img")

    });
    this.dragulaServiceDropSubscription = this.dragulaService.dropModel().subscribe((value) => {
      let selectedFloorId: any
      if (value && value.item !== undefined) {
        selectedFloorId = value.item.id;
      }
      const floor = this.buildingDetails.floors.filter((x: any) => x.id === selectedFloorId)
      if (floor) {
        this.selectFloor(floor[0])
      }
    });
    this.showSpinner = false;
    this.addPlansCreateButtonChange = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event: any) {
    if (this.buildingForm.dirty) {
      $event.returnValue = false;
    }
  }


  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this.buildingForm.dirty) {
      if (window.confirm(ApplicationNotification.pageReload)) {
        this.completeFloorFromNavigation();
        this.buildingForm.markAsPristine();
        this.uploadedFiles = null;
        this.isnewFloor = false;
        this.canOpenBroweserPopup = true;
        this.isNewPlan = false;
      } else {
        this.buildingForm.markAsPristine();
        this.uploadedFiles = null;
        this.canOpenBroweserPopup = true;
        this.isnewFloor = false;
        this.isNewPlan = false;
      }
    }
  }

  private isBuildingDataChange(data: any) {
    return _.isEqual(this.defaultBuildingDetails, data) ? true : false;
  }


  private getBuidlingDetails() {
    this.siteService.getBuilding(this.buildingId).subscribe((result: any) => {
      this.buildingDetails = result;
      this.defObjBuilding = _.cloneDeep(result);
      this.buildingDetails.floors.forEach((floor: any) => {
        floor['oldFloor'] = true;
        if (!floor.fileName) {
          const name: any[] = floor.fileLocation.split('/');
          floor['fileName'] = name[name.length - 1]
        }
      })

      this.defaultBuildingDetails = _.cloneDeep(result)
      this.menu.subMenu.editableLabel.value = this.buildingDetails.name;
      if (!Utilities.isEmpty(result.floors)) {
        this.selectFloor(result.floors[this.buildingDetails.floors.length - 1], true);
      }
      this.isFloorPlanAddedToAWS = true;
      this.isBuildingUpdated = true;
    });
  }

  createForm() {
    this.buildingForm = this.fb.group({
      floorName: ['', [Validators.required, Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
    })
  }

  private resetForm() {
    this.createForm();
    this.selectedFloorIndex = null;
    this.selectedFloor = null;
    this.isEditMode = false;
  }
  closeModal(closeAllModal?: any, navigate?: any) {
    this.PreviousModalDTO.closeAllModal = closeAllModal;

    if (navigate) {
      if (this.canOpenBroweserPopup && (this.buildingForm.dirty)
      ) {
        if (window.confirm(ApplicationNotification.pageReload)) {
          this.completeFloorFromNavigation(closeAllModal);
          this.buildingForm.markAsPristine();
          this.uploadedFiles = null;
          this.isnewFloor = false;
          this.canOpenBroweserPopup = true;
          this.isNewPlan = false;
        } else {
          this.buildingForm.markAsPristine();
          this.uploadedFiles = null;
          this.canOpenBroweserPopup = true;
          this.isnewFloor = false;
          this.isNewPlan = false;
        }
      }
    }
    this.bsModalPlanRef?.hide();
    this.bsModalRef?.hide();
    this.closeAll.emit();
    this.buildingfileUpload.nativeElement.value = '';
  }

  updateBuildingdetailsAray(isBack?: any) {
    this.buildings[this.currentBuildingIndex] = this.buildingDetails
    this.PreviousModalDTO.buildings = [...this.buildings];
    this.buildingDetails.floors.forEach((x: any, index: any) => {
      this.responseFromUpdate = this.updateImage(this.isBack, index)
    });
    this.nextOrBack(isBack)
    this.isBack = false
  }
  nextOrBack(isBack?: any) {
    if (isBack) {
      this.currentBuildingIndex = this.currentBuildingIndex - 1;
    } else {
      this.currentBuildingIndex = this.currentBuildingIndex + 1;
    }
    this.buildingDetails = new Building()
    this.createForm();
    this.buildingDetails = this.buildings[this.currentBuildingIndex];
    if (this.buildingDetails !== undefined && !this.buildingDetails['floors']) {
      this.buildingDetails['floors'] = [];
    }
    if (this.buildingDetails !== undefined && this.buildingDetails.floors.length > 0) {
      this.selectFloor(this.buildingDetails.floors[0], true);
    }
    this.onChanges();
    this.cd.detectChanges()
  }

  updateImage(isBack: any, index: any) {
    const newUploadedFile = this.buildingDetails.floors.filter((x: any) => x.isUpdateFromEditor && x.isNewFile);
    if (Utilities.isEmpty(newUploadedFile)) {
      return true;
    } else {
      const observable: any = [];
      const signedObservable: any = [];
      newUploadedFile.forEach((item: any) => {
        const fileName = new Date().getTime() + item.fileData.extension;
        const reqData = {
          file_name: fileName,
          image_location: 'FLOOR_IMAGE'
        }
        item.fileLocation = fileName;

        if (item.isUpdateFromEditor) {
          this.getCanvasBlob(item.fileData.fileData, item.awsImageURL).then(BlobImage => {
            item.fileData.fileData = BlobImage
          });
        }
        signedObservable.push(this.siteService.getPresignedUrl(reqData))
        index += 1;
      });
      forkJoin(signedObservable).subscribe((result: any) => {
        let i = 0;
        newUploadedFile.forEach((item: any) => {
          item.fileName = item.fileData.name;
          item.isNewFile = true;
          observable.push(this.siteService.uploadFile(result[i].message, item.fileData.fileData));
          i = i + 1;
        })
        forkJoin(observable).subscribe(() => {
          newUploadedFile.forEach((x: any) => { x.isNewFile = false })
          return true;
        })
      })
    }
    return true;
  }

  private saveChanges(fromPopUp?: any, closeAll?: any, isUpdateFromEditor?: any): any {
    this.PreviousModalDTO.closeAllModal = closeAll;
    const buildingDetails = _.cloneDeep(this.buildingDetails);
    buildingDetails.floors.forEach((floor: any) => {
      if (isNaN(Number(floor.id))) {
        delete floor.id;
      }
    });
    buildingDetails.floors.forEach((floor: any) => {
      if (floor['oldFloor']) {
        delete floor['oldFloor'];
      }
    })
    this.btnService.disable()

    this.siteService.updateBuilding(this.siteId, buildingDetails)
      .subscribe(() => {
        this.btnService.enable()
        // this.eventService.broadcast(EventEmitterType.SiteViewChanged, null);
        if (!this.isSiteEdit) {
          if (this.addPlansCreateButtonChange) {
            // this.eventService.broadcast(EventEmitterType.SiteViewChanged, null);
          } else {
            this.messageService.successMessage(`Building: ${buildingDetails.name}`, 'Saved Successfully');
            if (this.isBackForBuildingClosePopup) {
              this.uploadedFiles = null;
              this.canOpenBroweserPopup = false;
              this.isnewFloor = false;
              this.isNewPlan = false;
              this.isBack = false;
              this.isBackForBuildingClosePopup = false;
              this.resetForm();
              this.closeModal();
            }
          }
        }
        if (fromPopUp) {
          this.updateBuildingdetailsAray(this.isBack);
        } else if (!isUpdateFromEditor) {
          this.buildings[this.currentBuildingIndex] = this.buildingDetails
          this.PreviousModalDTO.buildings = [...this.buildings];
          this.closeAllModal.emit();
        }
        this.isBuildingUpdated = true;
        this.isBack = false
      }, (err: any) => {
        this.btnService.enable()
      })
  }
  canDisableButons(isBack: any) {
    const temp = this.currentBuildingIndex + 1
    if (!isBack) {
      if (this.buildings.length === 1) {
        this.isDisableBack = true;
        this.disableNext = true;
      }
      else if (temp !== 0 && temp === this.buildings.length - 1) {
        this.disableNext = true;
        this.isDisableBack = false;
      } else if (temp !== 0 && temp < this.buildings.length - 1) {
        this.disableNext = false;
        this.isDisableBack = false;
      }
    } else {
      if (this.buildings.length === 1) {
        this.isDisableBack = true;
        this.disableNext = true;
      }
      else if (this.currentBuildingIndex === 1) {
        this.isDisableBack = true;
        this.disableNext = false;

      } else if (this.currentBuildingIndex !== 1) {
        this.isDisableBack = false;
        this.disableNext = false;

      }
    }
  }
  back(isClosePopup?: boolean) {
    if (!this.isDisableBack) {
      this.canDisableButons(true)
    }
    this.isBack = true;
    if (this.canOpenBroweserPopup && (this.buildingForm.dirty)) {
      if (window.confirm(ApplicationNotification.pageReload)) {
        this.buildingForm.markAsPristine();
        this.isBackForBuildingClosePopup = true;
        this.completeFloor(true);

        this.uploadedFiles = null;
        this.isnewFloor = false;
        this.canOpenBroweserPopup = false;
        this.isNewPlan = false;

      } else {
        this.uploadedFiles = null;
        this.canOpenBroweserPopup = false;
        this.isnewFloor = false;
        this.isNewPlan = false;
        this.isBack = false;
        this.isBackForBuildingClosePopup = false;
        this.closeModal();

      }
    } else {
      this.updateBuildingdetailsAray(true);
    }

  }
  next() {
    this.canDisableButons(false)
    if (this.canOpenBroweserPopup || (this.buildingForm.dirty)) {
      this.completeFloor(true);
    } else {
      this.updateBuildingdetailsAray();
    }
  }
  goToPrevious() {
    if (this.buildings.length > 0) {
      if (this.buildingForm.dirty) {
        this.back(true);
      } else {
        this.backToSite.emit();
      }
    } else {
      this.closeModal(true, 'close');
    }
  }

  private saveChangesFromNavigation(closeAllModal?: any): any {
    this.PreviousModalDTO.closeAllModal = closeAllModal;
    const buildingDetails = _.cloneDeep(this.buildingDetails);
    buildingDetails.floors.forEach((savedFloor: any) => {
      if (isNaN(Number(savedFloor.id))) {
        delete savedFloor.id;
      }
    });
    if (this.isEditBuildingMode) {
      buildingDetails.floors.forEach((updatedFloor: any) => {
        if (updatedFloor['oldFloor']) {
          delete updatedFloor['oldFloor'];
        }
      })
      this.btnService.disable()

      this.siteService.updateBuilding(this.siteId, buildingDetails)
        .subscribe(() => {
          this.btnService.enable()
          this.addPlans.emit();
          this.bsModalRef?.hide();
          this.bsModalPlanRef?.hide();
          this.messageService.successMessage(`Building: ${buildingDetails.name}`, 'Saved Successfully');
        });
    } else {
      this.siteService.addBuilding(this.siteId, buildingDetails)
        .subscribe(() => {
          this.btnService.enable()
          this.bsModalRef?.hide();
          this.bsModalPlanRef?.hide();
          this.messageService.successMessage(`Building: ${buildingDetails.name}`, 'Created Successfully');
        });
    }
    // this.eventService.broadcast(EventEmitterType.SiteViewChanged, null);
  }


  ngOnInit(): void {
    this.initCall();
    this.canDeletePlan = this.authService.getUserKeyData().userRole === Roles.Administrator || this.authService.getUserKeyData().isAdmin;

    const modalBody: any = document.getElementById("modal-body");
    if (modalBody) {
      this.buildingBody = modalBody;
    }

    window.scrollBy(0, 0);
    this.buildingDetails = new Building();
    this.body = document.body;

    this.createForm();
    this.isEditBuildingMode = true;
    this.getBuidlingDetails();

    this.sub = this.route.queryParams.subscribe(params => {
      this.isSiteEdit = !Utilities.isEmpty(params['siteedit']);
      if (!this.isSiteEdit) {
        this.menu.subMenu.hideBackButton = false;
        this.menu.subMenu.backButtonActionType = HeaderActionType.Event;
        this.menu.subMenu.backButtonName = "Back";
        this.menu.subMenu.popup = {
          event: EventEmitterType.BackToBuildingList
        };
      }
      // this.eventService.broadcast(EventEmitterType.RouteChanged, this.menu);
    })

    this.subscribe();

    setTimeout(() => {
      autoScroll([
        this.autoscroll?.nativeElement
      ], {
        margin: 20,
        maxSpeed: 5,
        scrollWhenOutside: true,
        autoScroll: function () {
          return this.down;
        }
      });
    }, 1);

    this.onChanges();

  }

  private onChanges(): void {
    // detect the changes made in form
    let name = '';
    this.buildingForm.valueChanges.subscribe(() => {
      if (this.buildingDetails.floors[this.selectedFloorIndex]) {
        if (this.buildingForm.controls['floorName'].valid) {
          this.buildingDetails.floors[this.selectedFloorIndex].name = this.buildingForm.controls['floorName'].value
        } else {
          name = this.buildingDetails.floors[this.selectedFloorIndex].name
        }
        if ((this.buildingForm.controls['floorName'].value && this.buildingForm.controls['floorName'].value !== '')) {
          name = this.buildingForm.controls['floorName'].value
        }
        if (this.buildingForm.controls['floorName'].invalid && this.buildingForm.controls['floorName'].value !== '') {
          this.buildingForm.controls['floorName'].setValue(name)
        }
      }
      this.navigationStart = true;
      this.cd.detectChanges();
    });
  }
  private subscribe() {
    this.cusSub = this.eventService.subscribe(EventEmitterType.customerChangeAttemp, () => {
      if ((this.buildingForm.dirty && this.navigationStart)
        || (this.navigationStart && this.isnewFloor) ||
        (this.isNewPlan && this.navigationStart)) {
        if (window.confirm(ApplicationNotification.pageReload)) {
          this.completeFloorFromNavigation();
          if (this.buildingForm.valid) {
            this.buildingForm.markAsPristine();
          }
          this.uploadedFiles = null;
          this.isnewFloor = false;
          this.isNewPlan = false;
          this.navigationStart = false;
        } else {
          this.buildingForm.markAsPristine();
          this.uploadedFiles = null;
          this.isnewFloor = false;
          this.isNewPlan = false;
          this.navigationStart = false;
        }
      }
    })

  }

  selectFloor(floor: any, isFromNew?: any) {
    const tempIndex = this.buildingDetails.floors.indexOf(floor);
    if (!isFromNew && tempIndex != this.selectedFloorIndex) {
      this.saveFloor();
    }
    setTimeout(() => {
      if (this.selectedFloor && this.selectedFloor.name === '') {
        this.selectedFloor.name = this.defaultFloorDataName
      }
      this.selectedFloor = floor;
      this.isEditMode = !Utilities.isEmpty(this.selectedFloor.name);
      this.selectedFloorIndex = this.buildingDetails.floors.map((e: any) => e.id).indexOf(this.selectedFloor.id);
      this.defaultFloorData = _.cloneDeep(floor)
      this.defaultFloorDataName = _.cloneDeep(floor.name)
      this.buildingForm.controls['floorName'].setValue(floor.name);
      this.uploadedFiles = null;
      this.errorMessage = null;
      if (!Utilities.isEmpty(floor.awsImageURL)) {
        this.uploadedFiles = new FileItem();
        this.uploadedFiles.name = floor.fileName;
      }
    }, 0);
  }

  saveFloor() {
    let floorData: any = [];
    this.buildingDetails.floors.forEach((x: any) => {
      if (x && x.isNewFile === undefined && !Utilities.isEmpty(x.awsImageURL)) {
        x.isNewFile = true;
      }
    });
    floorData = this.buildingDetails.floors[this.selectedFloorIndex];
    if (floorData) {
      floorData.fileName = _.cloneDeep(floorData.fileLocation)
      if (!Utilities.isNull(floorData) && this.buildingForm.controls['floorName'].valid) {
        floorData.name = this.buildingForm.controls['floorName'].value;
      }
      if (this.uploadedFiles && !this.uploadedFiles.isImageEditFromEditor) {
        if (!Utilities.isNull(this.uploadedFiles) && !Utilities.isNull(this.uploadedFiles.fileData)) {
          floorData.isNewFile = true;
          floorData.fileData = Object.assign({}, this.uploadedFiles);
          const reader = new FileReader();
          reader.onloadend = () => {
            floorData.awsImageURL = reader.result as string;
            floorData.thumbnailAwsImageURL = reader.result as string;
            floorData.height = this.img.nativeElement.naturalHeight;
            floorData.width = this.img.nativeElement.naturalWidth;
            this.uploadedFiles = null;
          }
          floorData.fileName = floorData.fileData.name;
          reader.readAsDataURL(floorData.fileData.fileData);
        } else {
          floorData.height = "";
          floorData.width = "";
        }
      }
    }
    if (floorData) {
      this.buildingDetails.floors[this.selectedFloorIndex] = floorData;
    }
  }

  // Get floor name for newly generated
  getFloorName(floorName: any, floorCount: any): any {
    const floorNames = Array<any>();
    this.buildingDetails.floors.forEach((floor: any) => {
      floorNames.push(floor.name);
    });

    // check floor name exist or not
    if (floorNames.includes(floorName)) {
      floorName = "New Plan " + floorCount;
      floorCount++;
      floorName = this.getFloorName(floorName, floorCount);
    }
    return floorName;
  }

  newFloor(name?: any): any {
    this.isnewFloor = true;
    const floorData: any = new Floor();
    floorData.name = name ? name : this.buildingDetails.floors.length > 0 ? this.getFloorName('New Plan', 1) : 'New Plan';
    floorData.id = `floor${this.buildingDetails.floors.length + 1}`
    let floorOrderNo = 1;
    if (this.buildingDetails.floors.length > 0) {
      floorOrderNo = this.getFloorOrderNo(this.buildingDetails.floors);
    } else {
      floorOrderNo = 1;
    }
    floorData.orderNumber = floorOrderNo;
    this.buildingDetails.floors.push(floorData);

    this.buildingForm.reset();
    this.selectFloor(this.buildingDetails.floors[this.buildingDetails.floors.length - 1], true);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.btnService.enable()
    if (this.cusSub) {
      this.cusSub.unsubscribe();
    }
    if (this.dragulaServiceDropSubscription) {
      this.dragulaServiceDropSubscription.unsubscribe();
    }
    //  this.buildingForm.reset();
    this.canOpenBroweserPopup = false;
    this.tempDeletdPlans = []
    this.navigationStart = false;
    this.uploadedFiles = null;
    this.isnewFloor = false;
    this.isNewPlan = false;
  }

  resetFloor() {
    this.selectFloor(new Floor());
  }

  completeFloor(fromPopUp?: any, closeAll?: any, isUpdateFromEditor?: any) {
    this.buildingForm.markAsDirty();
    this.navigationStart = false;
    this.btnService.enable()
    for (let i = 0; i < this.buildingDetails.floors.length; i++) {
      this.buildingDetails.floors[i].orderNumber = i + 1;
    }
    if (this.selectedFloor && this.selectedFloor.name === '') {
      this.selectedFloor.name = this.defaultFloorDataName
    }
    this.saveFloor();
    const timeOutFloor = setTimeout(() => {
      const newUploadedFile = this.buildingDetails.floors.filter((x: any) => x.isNewFile);
      if (Utilities.isEmpty(newUploadedFile)) {
        this.saveChanges(fromPopUp, closeAll);
      } else {
        const observable: any = [];
        const signedObservable: any = [];
        let index = 0;
        newUploadedFile.forEach((item: any) => {
          let extension = "";
          if (item.fileData.fileData.extension !== undefined) {
            extension = item.fileData.fileData.extension;
          } else {
            extension = item.fileData.extension;
          }
          if (extension) {
            const fileName = Utilities.getCurrentDateTime() + extension;
            const reqData = {
              file_name: fileName,
              image_location: 'FLOOR_IMAGE'
            }
            item.fileLocation = fileName;

            if (item.isUpdateFromEditor) {
              this.getCanvasBlob(item.fileData.fileData, item.awsImageURL).then(BlobImage => {
                item.fileData.fileData = BlobImage
              });
            }
            signedObservable.push(this.siteService.getPresignedUrl(reqData))
            index += 1;
          }
        });
        forkJoin(signedObservable).subscribe({
          next: (result: any) => {
            let i = 0;
            newUploadedFile.forEach((newItem: any) => {
              newItem.fileName = newItem.fileData.name;
              newItem.isNewFile = true;
              observable.push(this.siteService.uploadFile(result[i].message, newItem.fileData.fileData));
              i = i + 1;
            })
            forkJoin(observable).subscribe({
              next: () => {
                this.saveChanges(fromPopUp, closeAll);
              }, error: () => {
                this.btnService.disable()
              }
            })
          }, error: () => {
            this.btnService.disable()
          }
        })
      }
      clearTimeout(timeOutFloor);
    }, 0);
    // this.canOpenBroweserPopup = false
    this.buildingForm.markAsPristine();
    this.changesSaved = true;
    const timeOut = setTimeout(() => {
      if (!fromPopUp) {
        this.buildingForm.reset();
      }
      this.eventService.broadcast(EventEmitterType.callListApi, {});
      clearTimeout(timeOut);
    }, 0);
  }

  completeFloorFromNavigation(closeAllModal?: any) {
    this.PreviousModalDTO.closeAllModal = closeAllModal;
    for (let i = 0; i < this.buildingDetails.floors.length; i++) {
      this.buildingDetails.floors[i].orderNumber = i + 1;
    }
    if (this.selectedFloor && this.selectedFloor.name === '') {
      this.selectedFloor.name = this.defaultFloorDataName
    }
    this.saveFloor();
    const newUploadedFile = this.buildingDetails.floors.filter((x: any) => x.isNewFile);
    if (Utilities.isEmpty(newUploadedFile)) {
      this.saveChangesFromNavigation(closeAllModal);
    } else {
      this.btnService.enable()
      const observable: any = [];
      const signedObservable: any = [];
      let index = 0;
      newUploadedFile.forEach((newUploadItem: any) => {
        const fileName = new Date().getTime() + newUploadItem.fileData.extension;
        const reqData = {
          file_name: fileName,
          image_location: 'FLOOR_IMAGE'
        }
        newUploadItem.fileLocation = fileName;
        if (newUploadItem.isUpdateFromEditor) {
          this.getCanvasBlob(newUploadItem.fileData.fileData, newUploadItem.awsImageURL).then(BlobImage => {
            newUploadItem.fileData.fileData = BlobImage
          });
        }
        signedObservable.push(this.siteService.getPresignedUrl(reqData))
        index += 1;
      });
      forkJoin(signedObservable).subscribe((result: any) => {
        let i = 0;
        newUploadedFile.forEach((newUploadedItem: any) => {
          newUploadedItem.fileName = newUploadedItem.fileData.name;
          newUploadedItem.isNewFile = true;
          observable.push(this.siteService.uploadFile(result[i].message, newUploadedItem.fileData.fileData));
          i = i + 1;
        })
        forkJoin(observable).subscribe(() => {
          this.saveChangesFromNavigation(closeAllModal);
        }, err => {
          this.btnService.disable()
        })
      }, () => {
        this.btnService.disable()

      })
    }
    // this.eventService.broadcast(EventEmitterType.SiteViewChanged, null);
  }

  deleteFloor(event: any): void {
    this.tempDeletdPlans.push(this.tempFloor.id)
    const index = this.buildingDetails.floors.indexOf(this.tempFloor);
    this.buildingDetails.floors.splice(index, 1);
    if (this.selectedFloorIndex === index) {
      if (this.buildingDetails.floors.length === 0) {
        this.buildingForm.reset();
        this.selectedFloor = null;
        this.selectedFloorIndex = event === null ? 0 : -1;
      } else {
        if (this.selectedFloorIndex > 0) {
          this.selectFloor(this.buildingDetails.floors[this.selectedFloorIndex - 1], true);
        } else {
          this.selectFloor(this.buildingDetails.floors[0], true);
        }
      }
      this.buildingForm.markAsDirty();
    }
  }

  removeFloor(floor: Floor) {
    this.buildingForm.markAsDirty();
    this.navigationStart = true;
    this.tempFloor = floor;
    const dataReplacer = {
      '{{#name}}': _.escape(floor.name)
    }
    const modalData: any = Utilities.contentReplacer(dataReplacer, _.cloneDeep(DeleteFloor));
    const initialState: ModalOptions = {
      initialState: modalData, class: DeleteFloor.dialogClass, ignoreBackdropClick: true
    };
    this.bsModalDelete = this.modalService.show(CommonPopupComponent, initialState);
    this.bsModalDelete.content.event.subscribe((r: any) => {
      if (r === DeleteFloor.btnSuccessText) {
        const index = this.buildingDetails.floors.indexOf(this.tempFloor);
        this.buildingDetails.floors.splice(index, 1);
        if (this.buildingDetails.floors && this.buildingDetails.floors.length > 0) {
          this.selectFloor(this.buildingDetails.floors[this.buildingDetails.floors.length - 1], true);
          this.selectedFloor = this.buildingDetails.floors[this.buildingDetails.floors.length - 1]
        }
        const floors: any = this.buildingDetails.floors;
        this.buildingDetails.floors = [];
        this.buildingDetails.floors = _.cloneDeep(floors);
        this.buildingForm.markAsDirty();
        this.bsModalDelete.hide();
        this.cd.detectChanges();
      }
    })
  }
  fileUploaded(event: any) {
    this.uploadedFile = event;
    this.navigationStart = true;
    this.canOpenBroweserPopup = true;
    this.buildingForm.markAsDirty();
    this.uploadFileLocally(true);
  }

  uploadFileLocally(isReplace?: any) {
    if (!Utilities.isNull(this.uploadedFile) && !Utilities.isNull(this.uploadedFile.fileData)) {
      if (isReplace) {
        this.isBuildingUpdated = false;
      }
      this.selectedFloor.fileData = Object.assign({}, this.uploadedFile);

      const reader = new FileReader();

      reader.onloadend = () => {
        this.selectedFloor.awsImageURL = reader.result as string;
        this.selectedFloor.thumbnailAwsImageURL = reader.result as string;
        this.selectedFloor.isNewFile = true;
        const floorIndex = this.buildingDetails.floors.map((e: any) => e.id).indexOf(this.selectedFloor.id);
        if (floorIndex > -1) {
          this.buildingDetails.floors[floorIndex] = this.selectedFloor;
        }
        this.uploadedFile = null;
        this.cd.detectChanges();
        if (isReplace) {
          this.saveReplaceImageChanges();
        }
      }

      reader.readAsDataURL(this.selectedFloor.fileData.fileData);
      setTimeout(() => {
        this.cd.detectChanges();
      }, 0);
    }
  }

  openFileDialog(): void {
    this.addPlansCreateButtonChange = false;
    this.buildingfileUpload.nativeElement.value = '';
    const timeOut = setTimeout(() => {
      this.buildingfileUpload.nativeElement.click();
      clearTimeout(timeOut);
    });
  }

  onFileDialogChange(event: any) {
    const target = event.target || event.srcElement;
    const files = target.files;
    if (files.length > 0) {
      const file = files[0];
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = (e: any) => {
          const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
          let header = "";
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          const fileType = this.fileUploadService.checkFileType(header);
          let fileMessage = this.fileUploadService.isValidBuildingFileWithMessage(file, fileType);
          let checkExt = '';
          if (file && file.name && file.name !== null) {
            checkExt = file.name.split('.').pop();
          }
          if (this.acceptFiles.toLowerCase().indexOf(checkExt.toLowerCase()) === -1) {
            fileMessage = FileUploadConstant.FILETYPEERRORMESSAGE
          }
          if (Utilities.isNull(fileMessage)) {
            if (fileType !== 'application/pdf') {
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  this.bsModelAddPlanRef.setClass('newBuildingM d-none')
                  const initialState: ModalOptions = {
                    initialState: { planImage: reader.result }, ignoreBackdropClick: true, class: 'newplanM'
                  };
                  this.bsModalRef = this.modalService.show(NewPlanPreviewComponent, initialState);
                  this.bsModalRef.content.planNameEvent.subscribe((r: any) => {
                    if (r) {
                      if (r !== false) {
                        this.onFileDialogChangeAfterPlanName(fileType, file, target, r)
                      } else {
                        this.buildingfileUpload.nativeElement.value = '';
                      }
                    }
                    this.bsModalRef.hide();
                    this.bsModelAddPlanRef.setClass('newBuildingM')
                  })
                  this.bsModalRef.onHidden?.subscribe((res: any) => {
                    this.bsModelAddPlanRef.setClass('newBuildingM')
                  })
                }
              });
            } else {
              this.onFileDialogChangeAfterPlanName(fileType, file, target)
            }
          } else {
            this.navigationStart = false;
            this.showSpinner = false;
            this.isFloorPlanAddedToAWS = true;
            this.messageService.errorMessage('Invalid File: ', fileMessage);
            target.value = '';
          }
        }
        reader.readAsArrayBuffer(file);
      });
    }
  }
  onFileDialogChangeAfterPlanName(fileType: any, file: any, target: any, item?: any) {
    this.checkPlanDetailUpdate();
    if (item) {
      this.newFloor(item.values);
    } else {
      this.newFloor();
    }
    this.navigationStart = false;
    this.showSpinner = true;
    this.isFloorPlanAddedToAWS = false;
    this.navigationStart = false;
    const newUploadedFile = this.buildingDetails.floors.filter((x: any) => x.isNewFile);
    if (newUploadedFile && newUploadedFile.length > 0) {
      const observable: any = [];
      const signedObservable: any = [];
      let index = 0;
      newUploadedFile.forEach((newFileItem: any) => {
        const fileName = new Date().getTime() + newFileItem.fileData.extension;
        const reqData = {
          file_name: fileName,
          image_location: 'FLOOR_IMAGE'
        }
        newFileItem.fileLocation = fileName;
        signedObservable.push(this.siteService.getPresignedUrl(reqData))
        index += 1;
      });
      forkJoin(signedObservable).subscribe((result: any) => {
        let i = 0;
        newUploadedFile.forEach((newItemFiles: any) => {
          newItemFiles.fileName = newItemFiles.fileData.name;
          newItemFiles.isNewFile = true;
          observable.push(this.siteService.uploadFile(result[i].message, newItemFiles.fileData.fileData));
          i = i + 1;
        })
      })
    }
    if (!Utilities.isNull(fileType) && FileUploadConstant.ALLOWEDIMAGEFILES.indexOf(fileType) > -1) {
      this.uploadedFile = this.fileUploadService.createFromObject(file);
      const uploadedFile = _.cloneDeep(this.uploadedFile);
      target.value = '';
      if (!this.isEditBuildingMode) {
        this.isNewPlan = false;
      } else {
        this.isNewPlan = true;

      }
      setTimeout(() => {
        this.uploadFileLocally();
        const fileName = new Date().getTime() + uploadedFile.extension;

        const reqData = {
          file_name: fileName,
          image_location: 'FLOOR_IMAGE'
        }
        this.selectedFloor.fileLocation = fileName;
        this.selectedFloor.fileName = fileName;

        this.siteService.getPresignedUrl(reqData).subscribe((signRes: any) => {
          this.siteService.uploadFile(signRes.message, uploadedFile.fileData).subscribe(() => {
            this.isFloorPlanAddedToAWS = true;
            this.saveBuildingFromUploadFile();
          });
        });
      }, 200);

    } else if (!Utilities.isNull(fileType) && fileType === 'application/pdf') {
      // pdf upload code
      if (!this.isEditBuildingMode) {
        this.isNewPlan = false;
      } else {
        this.isNewPlan = true;

      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", this.buildingDetails.name);
      formData.append("address",this.buildingDetails.address);

      this.siteService.uploadPdfFile(this.siteId, this.buildingDetails.id, formData).subscribe((result: any) => {
        const tempBuildingDetails = _.cloneDeep(result);
        tempBuildingDetails.floors = tempBuildingDetails.floors.map((plan: any) => {
          if (!this.tempDeletdPlans.some((x: any) => x === plan.id)) {
            return plan;
          }

        })
        tempBuildingDetails.floors = tempBuildingDetails.floors ? tempBuildingDetails.floors.filter((x: any) => !Utilities.isNull(x)) : []
        this.buildingDetails = tempBuildingDetails;
        this.buildingDetails.floors.forEach((floor: any) => {
          if (this.defaultBuildingDetails.floors.find((x: any) => x.id === floor.id)) {
            floor['oldFloor'] = true;
          } else {
            this.PreviousModalDTO.newFloors.push(floor)
          }
        })
        this.buildingId = this.buildingDetails.id;
        if (!Utilities.isEmpty(result.id) && !this.isEditBuildingMode && this.router.url.indexOf('building/create') > -1) {
          this.router.navigate(['secure', 'site', this.siteId, 'building', 'edit', result.id]);
        } else {
          this.isNewPlan = false;
          this.navigationStart = true;
        }
        this.menu.subMenu.editableLabel.value = this.buildingDetails.name;
        if (!Utilities.isEmpty(result.floors)) {
          this.selectFloor(result.floors[result.floors.length - 1], true);
        }
        this.showSpinner = false;
        this.isFloorPlanAddedToAWS = true;
        this.isBuildingUpdated = true;
        target.value = '';
        this.messageService.successMessage('Plan(s)', 'Added successfully');
      });

    }
  }
  saveBuildingFromUploadFile(isCurrentSelection?: any) {
    this.saveFloor();
    const buildingDetails = _.cloneDeep(this.buildingDetails);

    // To send only newly added plans and that are common in building i.e. issue no :"5337,5467",
    if (!this.defObjBuilding || (this.defObjBuilding && this.buildingDetails.id !== this.defObjBuilding.id)) {
      this.defObjBuilding = buildingDetails;
    }
    const updateBuildingData = _.cloneDeep(this.defObjBuilding);
    updateBuildingData.floors = [];
    buildingDetails.floors.forEach((floor: any) => {
      const data = this.defObjBuilding.floors.find((x: any) => x.id === floor.id);
      if (data && data.length > 0) {
        updateBuildingData.floors.push(data);
      } else {
        updateBuildingData.floors.push(floor);
      }
    });

    // updateBuildingData.floors.forEach((element: any) => {
    //   const data = this.defObjBuilding.floors.find((x: any) => x.id !== element.id && x.isNewFile);
    //   if (data) {
    //     const checkBuildingExsits = updateBuildingData.floors.find((x: any) => x.id !== element.id && x.isNewFile);
    //     if (checkBuildingExsits) {
    //       updateBuildingData.floors.push(data);
    //     }
    //   }
    // });

    // code end for 5337

    buildingDetails.floors.forEach((savedUpdatedFloor: any) => {
      if (isNaN(Number(savedUpdatedFloor.id))) {
        delete savedUpdatedFloor.id;
      }
    });
    this.showSpinner = false;
    this.isBuildingUpdated = false;
    if (this.isEditBuildingMode) {
      // this.newFloors =  buildingDetails.floors.filter(x=>!x.oldFloor)
      buildingDetails.floors.forEach((updatedFloorCheck: any) => {
        if (updatedFloorCheck['oldFloor']) {
          delete updatedFloorCheck['oldFloor'];
        }
      })

      this.siteService.updateBuilding(this.siteId, updateBuildingData)
        .subscribe((result: any) => {
          this.isBuildingUpdated = true;
          const oldbuildingDetails = _.cloneDeep(this.buildingDetails.floors)
          const defBuildingDetails = _.cloneDeep(result);
          this.defObjBuilding = _.cloneDeep(result);
          // this.buildingDetails.floors = [];
          // this.buildingDetails.floors = _.cloneDeep(result.floors);
          oldbuildingDetails.forEach((floor: any, index: any) => {
            this.buildingDetails.floors[index] = defBuildingDetails.floors[index]
            this.buildingDetails.floors[index].thumbnailAwsImageURL = defBuildingDetails.floors[index].thumbnailAwsImageURL;
            this.buildingDetails.floors[index].awsImageURL = defBuildingDetails.floors[index].awsImageURL;
            if (isNaN(Number(floor.id)) && (floor.thumbnailAwsImageURL.indexOf('data:image') > -1)) {
              this.PreviousModalDTO.newFloors.push(defBuildingDetails.floors[index]);
            }
          })
          this.buildingDetails.floors.forEach((element: any) => {
            if (this.defaultBuildingDetails.floors.find((x: any) => x.id === element.id)) {
              element['oldFloor'] = true;
            } else {
              this.PreviousModalDTO.newFloors.push(element)
            }
            element.isNewFile = false;
            element.isUpdateFromEditor = false;
          });
          this.canOpenBroweserPopup = false;
          this.buildingForm.markAsPristine();
          if (!isCurrentSelection) {
            this.selectFloor(this.buildingDetails.floors[this.buildingDetails.floors.length - 1], true);
          }
          this.showSpinner = false;
          this.messageService.successMessage('Plan', 'Added successfully')
        });
    } else {
      this.isNewPlan = false;
      this.btnService.disable()

      this.siteService.addBuilding(this.siteId, buildingDetails)
        .subscribe((result: any) => {
          this.btnService.enable()

          this.buildingDetails = result;
          if (!isCurrentSelection) {
            this.selectFloor(this.buildingDetails.floors[this.buildingDetails.floors.length - 1], true);
          }
          this.buildingDetails.floors.forEach((element: any) => {
            element.isNewFile = false;
            element.isUpdateFromEditor = false;
          });
          this.buildingId = this.buildingDetails.id;
          if (!Utilities.isEmpty(result.id && !this.isEditBuildingMode)) {
            this.messageService.successMessage('Plan', 'Added successfully')
            this.isNewPlan = false;
            this.navigationStart = false;
            if (this.router.url.indexOf('building/create') > -1) {

              this.router.navigate(['secure', 'site', this.siteId, 'building', 'edit', result.id]);
            }
          }
          this.isEditBuildingMode = true;
          this.buildingForm.markAsPristine();
          this.addPlansCreateButtonChange = true;
        }, () => {
          this.btnService.enable()
        })
    }
  }

  openImageEditor(imageObj: any, event: any) {

    const modalData: any = {
      file: imageObj,
      caption: imageObj.fileName,
      image: imageObj.awsImageURL,
      isDefaultViewOnly: true,
      assosicatedProject: [],
      isCanEdit: this.selectedFloor.canResizePlanMarkup,
      canResizePlanMarkup: this.selectedFloor.canResizePlanMarkup,
      equipment: null,
      canUpdate: true,
      isSliderShow: false,
      index: 0,
      isFromEditBuilding: true,
      showImageEditor: true,
      showResizeTransformCrop: false,
      showVisibiltyHiddenClass: true,
      pixieForPlan: true,
      showImageEditorForBuilding: true,
      isFromFloorPlan: false
    };

    const initialState: ModalOptions = {
      initialState: modalData
      , ignoreBackdropClick: true, class: 'modal-page-cover modal-markup'
    };
    this.bsModalImage = this.modalService.show(PixieComponent, initialState);
    const pixieSub = this.bsModalImage.content.saveImage.subscribe((item: any) => {
      if (item) {
        this.showSpinner = false;
        this.isBuildingUpdated = false;
        this.isSaveRemain = true;
        imageObj.awsImageURL = item.image.awsImageURL;
        imageObj.thumbnailAwsImageURL = item.image.awsImageURL;
        imageObj.isNewFile = true;
        this.uploadedFiles.fileData = item.image.fileData;
        this.uploadedFiles.isImageEditFromEditor = true;
        this.buildingDetails.floors.filter((x: any) => x.id === imageObj.id)[0].fileData = item.image.fileData;
        this.buildingDetails.floors.filter((x: any) => x.id === imageObj.id)[0].awsImageURL = imageObj.awsImageURL;
        this.buildingDetails.floors.filter((x: any) => x.id === imageObj.id)[0].thumbnailAwsImageURL = imageObj.awsImageURL;
        this.buildingDetails.floors.filter((x: any) => x.id === imageObj.id)[0].isNewFile = true;
        this.buildingDetails.floors.filter((x: any) => x.id === imageObj.id)[0].isUpdateFromEditor = true;

        const fileName = new Date().getTime() + '.png';
        this.buildingDetails.floors.filter((x: any) => x.id === imageObj.id)[0].fileLocation = fileName;
        this.buildingDetails.floors.filter((x: any) => x.id === imageObj.id)[0].fileName = fileName;
        const reqData = {
          file_name: fileName,
          image_location: "FLOOR_IMAGE"
        };
        let blob: any;
        item.fileLocation = fileName;
        this.getCanvasBlob('png', imageObj.awsImageURL).then(BlobImage => { blob = BlobImage; });
        this.siteService.getPresignedUrl(reqData).subscribe((r: any) => {
          this.siteService.uploadFile(r.message, blob).subscribe(() => {
            this.saveBuildingFromUploadFile();
          })
        })
        pixieSub.unsubscribe();
      }
    })
    this.bsModalRef.onHidden?.subscribe(() => {
      pixieSub.unsubscribe();
    })
  }

  private getCanvasBlob(format: ValidFormats, data: string): Promise<Blob> {
    return new Promise(resolve => {
      let blob;
      if (format === 'json') {
        blob = new Blob([data], { type: 'application/json' });
      } else {
        const contentType = 'image/' + format;
        data = data.replace(/data:image\/([a-z]*)?;base64,/, '');
        blob = (b64toBlob as any)(data, contentType);
      }

      resolve(blob);
    });
  }

  getFloorOrderNo(floors: any) {
    if (floors.length > 0) {
      const no: any = _.maxBy(floors, 'orderNumber');
      return no.orderNumber + 1;
    } else {
      return 1;
    }
  }

  onDragulaModelChanges(floors: any) {
    if (floors) {
      floors.forEach((element: any, index: any) => {
        element.orderNumber = index + 1;
      });
      this.buildingForm.markAsDirty();
      this.canOpenBroweserPopup = true;
    }
    this.buildingDetails.floors = _.cloneDeep(floors);
  }
  planSelectModal() {
    this.bsModelAddPlanRef.setClass('newBuildingM d-none')
    const initialState: ModalOptions = {
      initialState: {}, ignoreBackdropClick: true, class: 'plan-selectM'
    };
    this.bsModalRef = this.modalService.show(ChoosePlanPopupComponent, initialState);
    this.bsModalRef.content.eventImgSelect.subscribe((r: any) => {
      if (r.data === "siteOwlImage") {
        this.onSaveBlankFloor(r.type)
        this.bsModalRef.hide()
      } else if (r.data === "localImage") {
        this.openFileDialog();
        this.bsModalRef.hide()
        this.bsModelAddPlanRef.setClass('newBuildingM')
      } else {
        this.bsModelAddPlanRef.setClass('newBuildingM')
        this.bsModalRef?.hide();
      }

      // this.bsModalRef?.hide();
    })
  }

  onSaveBlankFloor(type?: any) {
    this.showSpinner = true;
    this.isUploadStart = true;
    this.isNewPlan = true;
    const observable: any = [];
    const signedObservable: any = [];
    let newfileName: any = ''
    let newimage: any;
    let blob: any;
    if (type === templateType.SQUARE) {
      newimage = templateImage.SQUAREIMAGE
    } else if (type === templateType.WIDERECTANGLE) {
      newimage = templateImage.WIDERECTANGLEIMAGE
    } else if (type === templateType.TALLRECTANGLE) {
      newimage = templateImage.TALLRECTANGLEIMAGE
    }
    const initialState: ModalOptions = {
      initialState: { planImage: newimage }, ignoreBackdropClick: true, class: 'newplanM'
    };
    this.bsModelAddPlanRef.setClass('newBuildingM d-none')
    this.bsModalPlanRef = this.modalService.show(NewPlanPreviewComponent, initialState);
    this.bsModalPlanRef.content.planNameEvent.subscribe((r: any) => {
      if (r) {
        this.newBlankFloor(newimage, r.values);
        const newUploadedFile = this.buildingDetails.floors.filter((x: any) => x.isNewFile && !x.isUpdateFromEditor);
        if (newUploadedFile && newUploadedFile.length > 0) {
          newUploadedFile.forEach((item: any) => {
            const fileName = new Date().getTime() + '.png';
            newfileName = fileName
            const reqData = {
              file_name: fileName,
              image_location: "FLOOR_IMAGE"
            };
            item.fileLocation = fileName;
            this.getCanvasBlob('png', newimage).then(
              BlobImage => {
                blob = BlobImage;
              }
            );
            signedObservable.push(
              this.siteService.getPresignedUrl(reqData)
            );

          });
          forkJoin(signedObservable).subscribe((result: any) => {
            let i = 0;
            newUploadedFile.forEach((item: any) => {
              item.fileName = newfileName;
              item.isNewFile = true;
              observable.push(this.siteService.uploadFile(result[i].message, blob));
              i = i + 1;
            });
            forkJoin(observable).subscribe(() => {
              this.saveBuildingFromUploadFile();
            });
          });
        }
      }
      this.bsModalPlanRef.hide();
      this.bsModelAddPlanRef.setClass('newBuildingM')
    })
    this.bsModalPlanRef.onHidden?.subscribe((res: any) => {
      this.bsModelAddPlanRef.setClass('newBuildingM')
    })
  }


  newBlankFloor(newimage: any, name: any) {
    const floorData: Floor = new Floor();
    floorData.name = name ? name : this.buildingDetails.floors.length > 0 ? this.getFloorName("New Plan", 1) : "New Plan";
    floorData.id = `floor${this.buildingDetails.floors.length + 1}`;
    floorData.isNewFile = true;
    let floorOrderNo = 1;
    if (this.buildingDetails.floors.length > 0) {
      floorOrderNo = this.getFloorOrderNo(this.buildingDetails.floors);
    } else {
      floorOrderNo = 1;
    }
    floorData.orderNumber = floorOrderNo;
    floorData.awsImageURL = newimage;
    floorData.thumbnailAwsImageURL = newimage;
    floorData.fileData = this.dataURLtoFile(newimage)
    floorData.fileData.fileData = {};
    floorData.fileData.extension = '.png';
    this.buildingDetails.floors.push(floorData);

    this.selectFloor(this.buildingDetails.floors[this.buildingDetails.floors.length - 1], true);
  }
  dataURLtoFile(dataurl: any) {
    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr: any = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'filename', { type: mime });
  }

  fillToolTipObj(event?: any, user?: any, position?: any) {
    event.stopPropagation();
    this.timer = setTimeout(() => {
      this.toolTipObj.name = user.name;
      if (this.toolTipObj.name === null || this.toolTipObj.name === undefined) {
        this.toolTipObj.name = "(" + user.email + ")";
      }

      this.toolTipObj.employer = user.employer;
      this.toolTipObj.picture = user.thumbnailAwsImageURL;
      if (user.workNumber) {
        this.toolTipObj.work = user.workNumber;
      }
      if (user.cellNumber) {
        this.toolTipObj.mobile = user.cellNumber;
      }
      this.toolTipObj.email = user.email;
      clearTimeout(this.timer)
    }, 300);

    this.toolTipObj = new TooltipModel();
  }
  out() {
    clearTimeout(this.timer)
  }
  toggleFloorStatus(event: any) {
    this.showArchivedFloor = !this.showArchivedFloor;

    const newSelectedFloor = this.buildingDetails.floors.filter((x: any) => !x.isArchived);
    if (!Utilities.isEmpty(newSelectedFloor)) {
      this.selectFloor(newSelectedFloor[0], true);
    } else {
      this.selectFloor(this.buildingDetails.floors[0], true);
    }
  }
  saveReplaceImageChanges() {
    const newUploadedFile = this.buildingDetails.floors.filter((x: any) => x.isNewFile);
    if (newUploadedFile && newUploadedFile.length > 0) {
      const observable: any = [];
      const signedObservable: any = [];
      let index = 0;
      newUploadedFile.forEach((replaceItem: any) => {
        const fileName = new Date().getTime() + replaceItem.fileData.extension;
        const reqData = {
          file_name: fileName,
          image_location: 'FLOOR_IMAGE'
        }
        replaceItem.fileLocation = fileName;
        signedObservable.push(this.siteService.getPresignedUrl(reqData))
        index += 1;
      });
      forkJoin(signedObservable).subscribe((result: any) => {
        let i = 0;
        newUploadedFile.forEach((newReplaceItem: any) => {
          newReplaceItem.fileName = newReplaceItem.fileData.name;
          newReplaceItem.isNewFile = true;
          observable.push(this.siteService.uploadFile(result[i].message, newReplaceItem.fileData.fileData));
          i = i + 1;
        });
        forkJoin(observable).subscribe(() => {
          this.saveBuildingFromUploadFile(true);
        });
      });
    }
  }
  checkPlanDetailUpdate() {
    if (this.buildingForm.dirty) {
      if (this.selectedFloor && this.buildingDetails.floors.filter((x: any) => x.id === this.selectedFloor.id) && this.buildingDetails.floors.filter((x: any) => x.id === this.selectedFloor.id).length > 0) {
        if (this.selectedFloor && this.buildingForm.controls['floorName'].value !== this.buildingDetails.floors.filter((x: any) => x.id === this.selectedFloor.id)[0].name) {
          this.selectedFloor.isUpdate = true;
          this.buildingDetails.floors.filter((x: any) => x.id === this.selectedFloor.id)[0].name = this.buildingForm.controls['floorName'].value;
        }
      }
      const buildingDetails = _.cloneDeep(this.buildingDetails);
      buildingDetails.floors.forEach((dirtyFloorChange: any) => {
        if (isNaN(Number(dirtyFloorChange.id))) {
          delete dirtyFloorChange.id;
        }
      });
      buildingDetails.floors.forEach((floorChange: any) => {
        if (floorChange['oldFloor']) {
          delete floorChange['oldFloor'];
        }
      })
      this.btnService.disable()

      this.siteService.updateBuilding(this.siteId, buildingDetails)
        .subscribe(() => {
          this.btnService.enable()
          if (!this.isSiteEdit) {
            if (this.addPlansCreateButtonChange) {
              // this.eventService.broadcast(EventEmitterType.SiteViewChanged, null);
            } else {
              this.messageService.successMessage(`Building: ${buildingDetails.name}`, 'Saved Successfully');
            }
          }

        });
    }
  }

  closeAllPopUpModal() {
    this.closeAllModal.emit(true);
    this.eventService.broadcast(EventEmitterType.callListApi, {});
  }


}
