import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-preview-import',
  templateUrl: './preview-import.component.html',
  styleUrls: ['./preview-import.component.scss'],
})
export class PreviewImportComponent implements OnInit {
  previewData: any;
  isCompleted: any;
  isDisableAction = false;
  public importCSV: EventEmitter<any> = new EventEmitter();
  constructor(private bsModalRef: BsModalRef) { }
  ngOnInit(): void {
    if (this.isCompleted) {
      this.previewData.deviceImportErrorList = this.previewData.deviceImportErrorList.filter((x: any) => x.severity === 0);
    }
  }
  closeModal(event: any, isImport: boolean) {
    event.preventDefault();
    event.stopPropagation();
    this.isDisableAction = true;
    this.importCSV.emit(isImport);
  }
}
