import { Equipment } from "./equipment";
// import { FileItem } from "app/file-upload";
import { Images } from "./images";
import { FileItem } from './file-item';
import { DeviceTypes } from "../constants";

export class FloorEquipment {
    id!: number;
    type?: string;
    equipment: Equipment;
    floorId: number;
    categoryName?: string;
    isAddAction?: boolean;
    isParent?: boolean;
    parentId?: any;
    typeName?: string;
    name?: string;
    abbreviatedName?: string;
    serialNumber?: string;
    actionStatus?: boolean;
    partNumber?: string;
    createdByUser?: any;
    updatedByUser?: any;
    manufacturer?: string;
    description?: string;
    installHours?: number | null;
    priority?: string;
    price?: string;
    dueDate?: any;
    installedByUser?: any;
    isPublishedToAMT?: boolean;
    referenceProjectId?: any;
    referenceProjectName?: any;
    fieldOfView?: number;
    ipAddress?: string;
    images: Images[];
    macAddress?: string;
    imageLeft?: string;
    imageTop?: string;
    isNew?: boolean = false;
    canAccessReference?: boolean;
    installStatus?: string;
    isDeleted?: boolean;
    buildingName?: string;
    buildingAddress?: string;
    buildingId?: number;
    floorName?: string;
    siteName?: string;
    siteId?: number;
    isSelectedDevice = false;
    isSelectedChildDevice?: boolean = false;
    status?: string;
    barcode?: string;
    installationDate?: string;
    deviceWarrantyExpirationDate?: string;
    laborWarrantyExpirationDate?: string;
    height?: number;
    deviceConnectedTo?: string;
    childFloorEquipments: FloorEquipment[] = [];
    isUpdate?: boolean
    //File
    fileName?: string;
    fileLocation?: string;
    isNewFile?: boolean;
    fileData?: FileItem;
    awsImageURL?: string;

    serviceTicketCount?: number;
    serviceTickets?: any;
    createdDateTime?: any
    dataType?: any;
    modifiedDateTime?: any;
    isEquipmentFavoriteForm = false;
    updatedBy?: string;
    createdBy?: string;
    projectId?: number;
    projectName?: string;
    projectStage?: string;
    installationNotes?: string;
    referenceFloorEquipmentId?: any;
    referenceProjectStage?: any;
    monitoredStatus?: string;
    monitoredDateTime?: string;
    monitoredLiveFeed?: any;
    autoUpdateDeviceStatus!: string;
    referenceFloorEquipmentCount?: number;
    installedBy?: any;
    isArchived?: boolean;
    isAddedDeviceFromDrop?: boolean;
    coverageArea?: boolean;
    coverageDirection?: any;
    coverageAngle?: any;
    coverageRange?: any;
    dimension?: any;
    dimensionWidth?: any;
    dimensionScale?: any;
    networkSwitchLinkId?: number;
    networkSwitchLinkName?: string;
    networkSwitchLinkFloorId?: number;
    networkSwitchLinkFloorName?: string;
    isSwitchSelected?: boolean;
    networkSwitchLinkEquipments?: any;
    pinColor?: string;
    statusCode?: number;
    isShowDragIcon?: boolean;
    displayPinColor?: any;
    displayPulse?: any;
    displayCoverageConeColor?: any;
    checkTicketsStatus?: any;
    displayBorderColor?: any;
    setTicketClassForPrimary?: any;
    isImage?: any;
    setClassForBadge?: any;
    isFlag?: any;
    flagNotes?: any;
    flagCreatedDateTime?: any;
    flagCreatedBy?: any;
    isChildFlag?: boolean;
    isFlagAdd?: boolean;
    isHub?: boolean;
    assignedToUser?: any;
    assignedTo?: any;
    isPrimaryDeviceTransparent?: boolean = false;
    cableCoordinates?: any;
    orderWithinParent = 0;
    tempId?: any;
    isShowConnectionsFilter?: any;
    secondaryDeviceCount?: any = 0;
    badgeColor?: any;
    isShowCoverageWithChild?: any
    archivedStatus?: string;
    customClass?: string; //setting custom class for image and pin related status.
    ticketCustomClass?: string; // custom class for ticket.
    ticketGreenBorder?: string; // adding green border to device if having ticket.
    categoryPinColor?: any
    secondaryPopupPosition?: any; // checking position for secondary popup.
    secondaryPopUpTop?: any; // check popup height for secondary section.
    classForSecondary?: any // class for secondary device.
    tooltipClass?: any;
    isConnectionLineOrCablePath?: boolean;
    cableLinkImageLeft?: any;
    cableLinkImageTop?: any;
    showTicketIcon?: any;
    port?: any;
    isLiveDevice: any = false;
    positionDroppingClient?: any;
    auditDeviceDetail?: any = [];
    showChildTicketIcon?: boolean;
    deviceLocation?: any;
    installedByUserName?: any;
    assignedToUserName?: any;
    customDeviceId?: any;
    connectionLength?: any;
    flagBadge?: boolean;
    deviceOpacity?: any;
    createdTrigger?: any;
    budgetedCost?: any;
    instructions?: String;
    isConnectionFromLiveDevice?:boolean;
    constructor(item: FloorEquipment) {
        this.equipment = item.equipment;
        this.type = DeviceTypes.Device;
        this.categoryName = item.categoryName;
        this.floorName = item.floorName;
        this.buildingName = item.buildingName;
        this.floorId = item.floorId;
        this.imageLeft = item.imageLeft;
        this.imageTop = item.imageTop;
        this.status = 'Operational';
        this.installStatus = 'Planned';
        this.isNew = false;
        this.siteName = item.siteName;
        this.siteId = item.siteId;
        this.isSelectedDevice = true;
        this.isEquipmentFavoriteForm = false;
        this.isPublishedToAMT = false;
        this.referenceFloorEquipmentId = null;
        this.canAccessReference = false;
        this.typeName = item.typeName;
        this.createdByUser = {};
        this.updatedByUser = {};
        this.name = '';
        this.childFloorEquipments = [];
        this.coverageArea = false;
        this.isFlag = false;
        this.isUpdate = true;
        this.isShowDragIcon = false;
        this.isFlagAdd = false;
        this.isHub = false;
        this.isPrimaryDeviceTransparent = false;
        this.cableCoordinates = null
        this.images = []
        this.showTicketIcon = false;
        this.isLiveDevice = false;
        this.showChildTicketIcon = false;
        this.connectionLength = 0;
        this.networkSwitchLinkEquipments = [];
        this.instructions = '';
        this.isConnectionFromLiveDevice=false;
    }
}
