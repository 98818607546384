import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderbyPipe implements PipeTransform {

  transform(value: any[], propertyName: string): any[] {
    if (propertyName) {
      value = _.orderBy(value, [(item) => {
        const propertyValue = item[propertyName];
        // Check if the property value is a string
        return typeof propertyValue === 'string' ? propertyValue.toLowerCase() : propertyValue;
      }], ['asc']);
      return value;
    }
    else {
      value = _.orderBy(value, ['orderNumber'], ['asc']);
      return value;
    }
  }

}
