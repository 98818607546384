import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'so-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit, OnChanges {

  @Input() pageloader!: boolean
  @Input() tableloader!: boolean
  @Input() smallloader!: boolean
  @Input() mainpageloader!: boolean
  @Input() xsloader!: boolean
  @Input() downloadloader!: boolean
  @Input() fullpageloader!: boolean
  @Input() pixieLoader!: boolean
  @Input() whLoader: boolean = false
  popupLoader = false;


  ngOnChanges(changes: SimpleChanges): void {
    this.popupLoader = false;
    const bodyClass = document.body.classList;
    if (bodyClass.contains('modal-open') && !this.pixieLoader && !this.downloadloader && !this.xsloader && !this.smallloader) {
      this.popupLoader = true;
    }
  }
  ngOnInit(): void {
    this.popupLoader = false;
    const bodyClass = document.body.classList;
    if (bodyClass.contains('modal-open') && !this.pixieLoader && !this.downloadloader && !this.xsloader && !this.smallloader) {
      this.popupLoader = true;
    }
  }
}