

export abstract class Environment {
    abstract readonly production: boolean;
    abstract readonly apiPath: string;
    abstract readonly cdnUrl: string;
    abstract readonly mixPanelToken: string;
    abstract readonly localstack: boolean;
    abstract readonly reportApiPath: string;
    abstract readonly envName: string;
    abstract readonly fronteggBaseUrl: string;
    abstract readonly fronteggClientId: string;
    abstract readonly sentryDSN: string;
    abstract readonly fronteggLogin: boolean;
}


export const PreSignedUrl = 'secure/api/aws/presigned_url?'
export const PresignedUrlToUploadS3 = 'secure/api/aws/multiple/presigned_url?'


export const PublicAPIPath = {
    login: 'public/api/web/login',
    resetPassword: 'public/api/user/password/reset',
    resetpasswordwith2FA: 'public/api/user/resetpasswordwith2FA',
    accountSetup: 'public/api/user/account/setup',
    verifyMFA: 'public/api/user',
    updateMFA: 'public/api/user/updatemfa',
    stubLogin: 'public/api/stub/login'
}
export const UserAPIPath = {
    user: 'secure/api/user',
    users: 'secure/api/users',
    changePassword: 'secure/api/user/password/change',
    twoFaVerify: 'secure/api/user/verify/2fa',
    resendTwoFACode: 'secure/api/user/resendcode/2fa',
    changeDeliveryMethod: 'secure/api/user/deliveryMethod',
    generateToken: 'secure/api/generatenewtoken',
    recentCustomer: 'secure/api/user/customers/lastactive/basic',
    activeCustomersByUser: 'secure/api/user/customers/basic',
    userDetails: 'secure/api/userdetails',
    getUserDetails: 'secure/api/user/details',
    addResource: 'secure/api/project/resources',
    EmailOrDeviceSubscribe: 'secure/api/profile/customer/user',
    profilePicture: 'secure/api/user/profile/image',
    userInvite: 'secure/api/customer/userinvite',
    employers: 'secure/api/users/employers',
    logout: 'secure/api/user/logout',
    headquartersList: 'secure/api/user/providers/basic',
    setProviderId: 'secure/api/userdetails?',
}

export const CustomerAPIPath = {
    customer: 'secure/api/customer',
    customers: 'secure/api/customers',
    activeCustomers: 'secure/api/active/customers/basic',
    GlobalSearch: 'secure/api/global/search',
    customerUserDetails: 'secure/api/customer/user/details',
    activeCustomersByUser: 'secure/api/user/customers',
    devicesFields: 'secure/api/user/favourite',
    sites: 'secure/api/site/list',
    favImportToCustomer: 'secure/api/floorequipment/copy/favourite/customer',
    projects: 'secure/api/customers/projects',
    managers: 'secure/api/project/managers',
    domotzTest: 'secure/api/customer/integration/test',
    domotzSave: 'secure/api/customer/integration',
}

export const ProviderAPIPath = {
    providers: 'secure/api/providers',
    provider: 'secure/api/provider',
    requester: 'secure/api/dashboard/manage/requester',
    role: 'secure/api/providerspecific/role',
}

export const SiteAPIPath = {
    list: 'secure/api/sitelist',
    site: 'secure/api/site',
    siteDetails: 'secure/api/sitedetails',
    assginedSites: `secure/api/user/site`,
    image: `secure/api/floor/equipment/image`,
    siteDropdownList: 'secure/api/site/dropdown',
    siteDetailsForLibrary: 'secure/api/planlibrary/site',
    domotzAgent: 'secure/api/customer/integration/agents',
    switchFloors: 'secure/api/floor/equipment/getSwitchConnection',
    switchFloorsPDF: 'secure/api/floor/equipment/generateSwitchConnectionPrintPDF',
    users: 'secure/api/customer/project',
    moveAndReorder: 'secure/api/move/buildingandfloor',
    building: 'secure/api/building',
    projects: 'secure/api/projectlist',
    presigned: 'secure/api/aws/site/file/presigned_url?',
    saveFiles: 'secure/api/site/files',
    allSiteList: 'secure/api/customer/sites',
    siteDetailsFromLib:'secure/api/customer/sitedetails'

}

export const CommonListAPIPath = {
    deviceStatus: 'secure/api/status',
    customerSpecificProjectRole: 'secure/api/customerspecific/project/role',
    customerSpecificRole: 'secure/api/customerspecific/role',
    projectChartData: 'secure/api/projectchartdata'

}

export const BuildingAPIPath = {
    building: 'secure/api/building',
    buildings: 'secure/api/buildings',
    newtWorkSwitches: 'secure/api/building/equipment/networkswitchdevice',
    siteAccess: 'secure/api/site/',
    projectAccess: 'secure/api/project/',
    deviceHistory: 'secure/api/floorequipment/history'
}
export const ProjectAPIPath = {
    project: 'secure/api/project',
    projectUsers: 'secure/api/customer/project',
    projectsList: 'secure/api/projectlist',
    image: `secure/api/project/floor/equipment/image`,
    projectAccessRole: 'secure/api/projectspecific/access/role',
    projectInformation: 'secure/api/projectinformation',
    presigned: 'secure/api/aws/project/file/presigned_url?',
    saveFiles: 'secure/api/project/files',
    projectAccess: `secure/api/project`,
    dashboardProjects: 'secure/api/dashboard/projects',
    projectlist: 'secure/api/projectlist',
    projects: 'secure/api/projects',
    category: 'secure/api/category',
}

export const CustomerUserAPIPath = {
    customerSpecificUsers: 'secure/api/customer/users',
    providerAssociated: 'secure/api/customer/associatedprovider',
    customerSpecificUser: 'secure/api/customer/user',
    amtUsers: 'secure/api/customer/amt-users',
    user: 'secure/api/user',
    activeUsers: 'secure/api/customer/assignees',
    providerNotAssociated: 'secure/api/customer/provider/not-associated',
    userProviderSites: 'secure/api/provider/user',
    customerProject: 'secure/api/integrator/add/project/resources',
    customerSite: 'secure/api/integrator/add/site/resources',
    addMultipleCustomerToUser: 'secure/api/addmultiplecustomers',
    customer: 'secure/api/customer',
    integratorAssociateCustomer: 'secure/api/associate/integrator/customer',
}

export const FloorAPIPath = {
    floor: 'secure/api/user/floor',
    category: 'secure/api/category',
    ticketCreate: 'secure/api/floor',
    showDeviceNameOnEquipment: `secure/api/user/showname/equipment`,
    showArchiveDevises: `secure/api/user/showarchived/equipment`,
    showPartNumberOnEquipment: `secure/api/user/showpartnumber/equipment`,
    showAbbreviatedNameOnEquipment: `secure/api/user/showabbreviatedname/equipment`,
    showFieldOfView: `secure/api/user/showfieldofview/equipment`,
    showConnectionLineOnEquipment: `secure/api/user/showconnectionline/equipment`,
    projectReportConfigColumn: `secure/api/report/project/configurationcolumn`,
    siteReportConfigColumn: `secure/api/report/livesite/configurationcolumn`,
    configReportFavoriteFields: `secure/api/customer/user/favourite`,
    history: `secure/api/floorequipment/history`,
    customerFavoriteEquipment: `secure/api/customer/favourite/equipment`,
    switchFloors: 'secure/api/floor/equipment/getSwitchConnection',
    switchFloorsPDF: 'secure/api/floor/equipment/generateSwitchConnectionPrintPDF',
    liveDevicesOnProject: 'secure/api/project',
    favouriteDeviceFields: `secure/api/site/csvimport/favourite/equipments`,
    showPlanMarkup: 'secure/api/user/showplanmarkup',
    deleteAllFavorites: 'secure/api/customer/favourite/equipments'
}

export const ListAPIPath = {
    admintRole: 'secure/api/customerspecific/admin/role',
    globalRole: 'secure/api/global/role',
    status: 'secure/api/status',
    userProjects: 'secure/api/user/projects',
    userSite: 'secure/api/user/sites',
}

export const DashboardAPIPath = {
    customer: 'secure/api/dashboard'
}

export const SerivceTicketAPIPath = {
    tickets: 'secure/api/service/tickets',
    triggerBy: 'secure/api/userdetails?',
    linkTicket: 'secure/api/floorequipment/associat/serviceticket',
    ticket: 'secure/api/service/ticket',
    file: 'secure/api/serviceticket',
    presigned: 'secure/api/aws/serviceticket/file/presigned_url?',
    InlineTicket: 'secure/api/serviceticket',
    quoteDelete: 'secure/api/serviceticket/quote/item',
    print_pdf: 'secure/api/serviceticket/quote',
    serviceTicket: 'secure/api/serviceticket',
}
export const PresignedAPIPath = {
    presignedUrl: 'secure/api/aws/multiple/presigned_url?'

}
export const ReportAPIPath = {
    devices: 'secure/api/report/devices',
    deviceCsvUpload: 'secure/api/site/csvimport/equipments',
    devicesFields: 'secure/api/customer/user/favourite',
    changeDevicesFields: 'secure/api/floor/equipment',
    inlineTicket: 'secure/api/serviceticket',
    projectChangeDevicesFields: 'secure/api/project/floor/equipment',
    inventoryReport: `secure/api/site/inventoryreport`,
    agingAndWarrantyReport: `secure/api/site/agingandwarrantyreport`,
    projectEquipmentImage: 'secure/api/project/floor/equipment/image',
    equipmentImage: 'secure/api/floor/equipment/image',
    removeFloorImageFromList: 'secure/api/floorequipment/image',
}

export const AuditAPIPath = {
    getAuditDetails: 'secure/api/audit',
    getAuditList: 'secure/api/audit/getAudits',
}

export const UIAPIPath = {
    buildings: `secure/api/buildings`,
    user: 'secure/api/user'
}

export const LIVEPLANNINGAPIPath = {
    livePlanningAgingReport: `secure/api/liveplanning/aging`,
    livePlanningLaborWarrantyReport: `secure/api/liveplanning/laborwarranty`,
    livePlanningDeviceWarrantyReport: `secure/api/liveplanning/devicewarranty`,
    inventoryReport: `secure/api/site/inventoryreport`,
    agingAndWarrantyReport: `secure/api/site/agingandwarrantyreport`

}
export const TEMPCOMMONVALUE = {
    COMMONVALUE: 0,
    S3URL: 'https://securesitebucket',
    BUILDING: '/building',
    FLOORUPLOADFILE: '/floor/uploadFile',

}

export const Sync = {
    syncStatusAPI: `secure/api/user`,
}