import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, CommonService, EventEmitterType, EventService, ListService, UserService, Utilities } from '@SiteOwl/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'so-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  returnUrl!: string;
  dashboardTabSelection!: string;
  removedOrInactiveCustomerSelectedSub!: Subscription;
  constructor(public router: Router, private route: ActivatedRoute, private eventService: EventService, private authService: AuthService, private listSerivce: ListService, private userService: UserService, private commonService: CommonService) {
    this.removedOrInactiveCustomerSelectedSub = this.eventService.subscribe(EventEmitterType.RemovedOrInactiveCustomerSelected, (r: any) => {
      this.authService.setCustomerId({ customerId: r, user: this.authService.getUserKeyData().user.email });
      this.userService.getUserDetails().subscribe({
        next: (result: any) => {
          this.listSerivce.getAdminSpecificRoleList().subscribe({
            next: (result: any) => {
              const adminRole: any = Utilities.seperateRoleDescription(result);
              this.commonService.setLocalStorageObject('adminRole', adminRole);
            }
          })
          this.authService.saveUserData(result);
          let dashboardTabSelection = 'design'
          if (result.dashboardTab) {
            if (result.dashboardTab === 'support') {
              if (!Utilities.isNull(result.providerName)) {
                if (result.isLiveSystemAccessForProvider) {
                  dashboardTabSelection = result.dashboardTab;
                }
              } else {
                dashboardTabSelection = result.dashboardTab;
              }
            } else {
              dashboardTabSelection = result.dashboardTab;
            }
          }
          this.router.navigate(['/secure', 'dashboard', dashboardTabSelection], { relativeTo: this.route });
        }
      });

    })
  }
  ngOnDestroy(): void {
    if (this.removedOrInactiveCustomerSelectedSub) {
      this.removedOrInactiveCustomerSelectedSub.unsubscribe();
    }
  }
}
