<div *ngIf="isShowLoader">
    <so-loader [mainpageloader]='true'></so-loader>
</div>
<form [formGroup]="captionDetailsForm" novalidate (click)="$event.stopPropagation();">
    <div class="modal-header d-flex align-items-center pb-3" >
        <!-- devices attached image title start -->
        <ul *ngIf="!showImageEditorForBuilding" class="modal-breadcrumb d-flex align-items-center">
            <li>
                <h1 class="modal-title pull-left">
                    Edit Image</h1>
            </li>
            <li>
                <div class="form-group">
                    <label for="">Image Name</label>
                    <input maxlength="80" type="text" class="form-control" formControlName="name"
                        placeholder="Image Name" (keydown.enter)="$event.preventDefault()">
                </div>
            </li>
        </ul>
        <!-- devices attached image title end -->
        <!-- plan library mark up plan title start  -->
        <span *ngIf="!projectId && showImageEditorForBuilding && !isFromFloorPlan">
            <ul class="modal-breadcrumb d-flex align-items-center">
                <li>
                    <h1 class="modal-title pull-left">
                        Plan Markup
                    </h1>
                </li>
                <li>
                    {{file?.name}}
                </li>
            </ul>
            <p class="text-start m-0">Markup on this
                Plan will overwrite any project-specific markup
                previously made on this Plan.
            </p>
        </span>
        <!-- plan library mark up plan title end  -->
        <!-- Live Site mark up plan title start -->
        <span *ngIf="siteId && showImageEditorForBuilding  && isFromFloorPlan">
            <ul class="modal-breadcrumb d-flex align-items-center">
                <li>
                    <h1 class="modal-title pull-left">
                        Floor Plan Markup
                    </h1>
                </li>
                <li>
                    {{file?.name}}
                </li>
            </ul>
            <p class="text-start m-0">Markup on this
                Plan will overwrite any project-specific markup
                previously made on this Plan.
            </p>
        </span>
        <!-- Live Site mark up plan title end -->
        <!-- Project specific mark up plan title start -->
        <span *ngIf="projectId && showImageEditorForBuilding && isFromFloorPlan">
            <ul class="modal-breadcrumb d-flex align-items-center">
                <li>
                    <h1 class="modal-title pull-left">
                        Project Specific Markup
                    </h1>
                </li>
                <li>
                    {{file?.name}}
                </li>
            </ul>
            <p class="text-start m-0 project-specific-header-note">Markup on this Plan will be saved to this Project and
                not reflected on other Sites
                or
                Projects using this Plan. Any future changes made to this Plan through the Plan Library will overwrite
                any
                markup made here.
            </p>
        </span>
        <!-- Project specific mark up plan title end -->
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <em class="far fa-close"></em>
        </button>
    </div>
    <div class="modal-body view-modal-popup">
     
        <div class="pixie-container" id="editor-container"></div>
        <!-- <div>test</div> -->
        <div class="footer-info footer-info-text" [ngClass]="{'image-source': !showImageEditorForBuilding}">
            <!-- floor mark up plan info -->
            <div class="file-upload justify-content-start text-start" *ngIf="file.id">
                <div class="date-format form-group clearfix ms-0 me-1 mb-1 d-flex" *ngIf="!showImageEditorForBuilding">
                    <div class="created d-flex">
                        <label>Image Source:</label>
                        <div class="created-by image-source">
                            <ng-container *ngIf="file?.imageSource">
                                <span title="{{file.imageSource.name}}"> {{file.imageSource.name}}</span>
                            </ng-container>
                            <ng-container *ngIf="!file.imageSource">
                                <span title="Live Site">   Live Site</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="date-format form-group clearfix ms-0 me-1 d-flex" *ngIf="!projectId || projectId && !showImageEditorForBuilding">
                    <div class="created">
                        <label>Created:</label>
                        <div class="created-by" title="{{file?.createdDateTime | date : 'M/dd/yy, h:mm a'}}">
                            {{createdTime}}
                        </div>
                        <div class="custom-tooltip">
                            <span class="created-by ms-1 me-2" (mouseout)="out()"
                                (mouseover)="fillToolTipObj($event,file?.createdByUser)">
                                {{createdBy}}
                                <so-tool-tip [toolTipObj]="toolTipObj"></so-tool-tip>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="date-format form-group clearfix ms-0 me-1" *ngIf="siteId && showImageEditorForBuilding  && isFromFloorPlan || !projectId && showImageEditorForBuilding && !isFromFloorPlan">
                    <div>
                        <label>Plan Image Updated:</label>
                        <div class="created-by"
                            title="{{file?.planImageModifiedDateTime | date : 'M/dd/yy, h:mm a'}}">
                            {{updatedTime}}
                        </div>
                        <div class="custom-tooltip">
                            <span class="created-by ms-1 me-2" (mouseout)="out()"
                                (mouseover)="fillToolTipObj($event,file?.planImageUpdatedByUser)">
                                {{updatedBy}}
                                <so-tool-tip [toolTipObj]="toolTipObj"></so-tool-tip>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="date-format form-group clearfix ms-0 me-1" *ngIf="!showImageEditorForBuilding">
                    <div>
                        <label>Updated:</label>
                        <div class="created-by"
                            title="{{file?.modifiedDateTime | date : 'M/dd/yy, h:mm a'}}">
                            {{updatedTime}}
                        </div>
                        <div class="custom-tooltip">
                            <span class="created-by ms-1 me-2" (mouseout)="out()"
                                (mouseover)="fillToolTipObj($event,file?.updatedByUser)">
                                {{updatedBy}}
                                <so-tool-tip [toolTipObj]="toolTipObj"></so-tool-tip>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- floor mark up plan info end -->
        </div>
    </div>
</form>