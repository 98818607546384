/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import moment from 'moment/moment';
import * as _ from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { DefaultValues, ExcludeToConvertToNA, InstallStatusFilter, OperationalStatusFilter, PrintOutType, RoleType, ViewingReportPreferences } from './constants/common.constant';
const PLAN_ARCHIVED = "Plan Archived";
type ValidFormats = "png" | "jpeg" | "json";
import b64toBlob from "b64-to-blob";
import { Category } from './model';

export class Utilities {
    constructor(private cd: ChangeDetectorRef) {

    }
    static objectToParams(object: any): string {
        return Object.keys(object).map((key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
        ).join('&');
    }

    static isEmpty(item: any) {
        return Utilities.isNull(item) || item.length === 0 || item === '' || item === 0;
    }

    static isEmptyWithTrim(item: any) {
        return Utilities.isNull(item) || item.length === 0 || item.trim() === '';
    }

    static isNull(item: any) {
        return item === 'null' || item === undefined || item === null || item === '';
    }

    static stringToDate(str: string, format: string = 'MM/DD/YYYY'): any {
        if (Utilities.isEmpty(str)) {
            return null;
        }
        return moment(str, format).toDate();
    }
    static calculatePercentage(value: number, total: number) {
        return (value / total) * 100;
    }

    static stringToDateObject(str: string, format: string = 'MM/DD/YYYY'): any {
        if (Utilities.isEmpty(str)) {
            return null;
        }
        const temp = moment(str, format);
        return { year: temp.year(), month: +temp.format('MM'), day: +temp.format('DD') };
    }

    static dateDifference(startDate: Date, endDate: Date): number {
        return moment(startDate).diff(moment(endDate), 'days');
    }
    static dateToDateObject(date: Date): any {
        if (Utilities.isEmpty(date)) {
            return null;
        }
        const temp = moment(date);
        return { year: temp.year(), month: +temp.format('MM'), day: +temp.format('DD') };
    }
    static getUrlWithoutDomain() {
        return window.location.href.replace(/^.*\/\/[^\/]+/, '');
    }

    static getLocalDateAsUTC(utcDate: any, addDay: any) {
        const date = new Date(new Date(utcDate).toUTCString());
        if (addDay) {
            date.setDate(date.getDate() + 1);
        }
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toUTCString();
    }

    static getCurrentDateTime() {
        return moment().toDate().getTime();
    }

    static getAllActiveSites(sites: any, isCustomerTechnician?: any) {
        if (isCustomerTechnician) {
            sites = sites.filter((x: any) => !x.isArchived && x.isTechnicianView);
            sites.forEach((element: any) => {
                element.buildings = this.getAllActiveBuildings(element.buildings, isCustomerTechnician);
                element.buildings.forEach((building: any) => {
                    building.floors = this.getAllActiveFloors(building.floors, isCustomerTechnician);
                });
            });
        } else {
            sites = sites.filter((x: any) => !x.isArchived);
            sites.forEach((element: any) => {
                element.buildings = this.getAllActiveBuildings(element.buildings);
                element.buildings.forEach((building: any) => {
                    building.floors = this.getAllActiveFloors(building.floors);
                });
            });
        }
        return sites;
    }

    static getAllActiveBuildings(buildings: any, isCustomerTechnician?: any) {
        if (isCustomerTechnician) {
            buildings = _.cloneDeep(buildings.filter((x: any) => !x.isArchived && x.isTechnicianView));
        } else {
            buildings = _.cloneDeep(buildings.filter((x: any) => !x.isArchived));
        }
        return buildings;
    }

    static getAllActiveFloors(floors: any, isCustomerTechnician?: any) {
        if (isCustomerTechnician) {
            floors = _.cloneDeep(floors.filter((x: any) => !x.isArchived && x.isTechnicianView));
        } else {
            floors = _.cloneDeep(floors.filter((x: any) => !x.isArchived));
        }
        return floors;
    }
    static getCompressedImage(src: any, maxSize: any, minSize: any) {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(src);

            reader.onload = (event: any) => {
                // blob stuff
                const blob = new Blob([event.target.result]); // create blob...
                const blobURL = window.URL.createObjectURL(blob); // and get it's URL

                // helper Image object
                const img = new Image();
                img.src = blobURL;
                let extension: any = undefined;
                // do something like this
                extension = "jpeg"
                img.onload = () => {
                    const resized = Utilities.resizeMe(img, extension, maxSize, minSize);
                    res(resized);
                }
                img.onerror = error => {
                    rej(error);
                }
            };
            // reader.readAsDataURL(this);
        });
    }
    static getCompressedIntegratorLogo(src: any) {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(src);

            reader.onload = (event: any) => {
                // blob stuff
                const blob = new Blob([event.target.result]); // create blob...
                const blobURL = window.URL.createObjectURL(blob); // and get it's URL

                // helper Image object
                const img = new Image();
                img.src = blobURL;
                let extension: any = undefined;
                // do something like this
                extension = "jpeg"
                img.onload = () => {
                    const resized = Utilities.resizeMe(img, extension, img.width, img.width);
                    res(resized);
                }
                img.onerror = error => {
                    rej(error);
                }
            };
            // reader.readAsDataURL(this);
        });
    }
    static resizeMe(img: any, extension: any, maxSize: any, minSize: any) {
        const canvas = document.createElement('canvas');
        const ctx: any = canvas.getContext("2d");
        let newX, newY;
        let imageWidthHeight: any;
        if (img.width > img.height) {
            imageWidthHeight = Math.max(img.width / maxSize, img.height / minSize);
        } else if (img.width < img.height) {
            imageWidthHeight = Math.max(img.width / minSize, img.height / maxSize);
        } else if (img.width === img.height) {
            imageWidthHeight = Math.max(img.width / minSize, img.height / minSize);
        }
        if (imageWidthHeight > 1) {
            newX = Math.round(img.width / imageWidthHeight);
            newY = Math.round(img.height / imageWidthHeight);
        } else {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(0, 0, img.width, img.height);
            ctx.drawImage(img, 0, 0, img.width, img.height);
            return canvas.toDataURL("image/jpeg", 0.7);
        }

        // resize the canvas and draw the image data into it
        canvas.width = newX;
        canvas.height = newY;
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, newX, newY);
        ctx.drawImage(img, 0, 0, newX, newY);
        return canvas.toDataURL("image/jpeg", 0.7);
    }
    static seperateRoleDescription(roles: any) {
        if (!Utilities.isEmpty(roles) && roles.length !== undefined && roles.length > 0) {
            roles.forEach((element: any) => {
                if (element.roleType === RoleType.INTEGRATOR || element.roleType === RoleType.HEADQUARTERS) {
                    element.displayRoleType = RoleType.ROLE;
                } else if (element.roleType === RoleType.SITE || element.roleType === RoleType.LIVESYSTEM || element.roleType === RoleType.USERROLE) {
                    element.displayRoleType = RoleType.LIVESYSTEMROLE;
                } else if (element.roleType === RoleType.CHECKPROJECT || element.roleType === RoleType.PROJECT || element.roleType === RoleType.PROJECT.toLocaleLowerCase()) {
                    element.displayRoleType = RoleType.PROJECTROLE;
                } else if (element.roleType === RoleType.CHECKPROJECTACCESS) {
                    element.displayRoleType = RoleType.ACCESSLEVEL;
                } else {
                    element.displayRoleType = element.roleType;
                }
                element.newDescription = element.description.split('|');
                element.isDefaultSelected = false;
            });
        } else if (!Utilities.isEmpty(roles)) {
            roles.newDescription = roles.description.split('|');
        }
        return roles;
    }

    static selectByDefaultRole(roles: any, id: any) {
        if (roles !== undefined) {
            if (!Utilities.isNull(id) && !Utilities.isEmpty(id)) {
                return roles.find((x: any) => x.id === id).roleKey
            } else {
                return roles.find((x: any) => x.id === 4).roleKey
            }
        }
    }
    static getActiveURL() {
        return new URL(window.location.href);
    }

    static getActiveURLQueryParams() {
        const url: any = new URL(window.location.href);
        if (url.search.includes('returnUrl')) {
            let customerId: any
            let equipmentid: any
            url.search = _.cloneDeep(decodeURIComponent(url.search));
            if (url.search.split('=').length > 0) {
                const newString = url.search.split('=');
                if (newString.length > 0) {
                    const custString = newString[2].split('&');
                    if (custString.length > 0) {
                        customerId = custString[0]
                        equipmentid = newString[newString.length - 1]
                        url.searchParams.set('customerId', parseInt(customerId))
                        url.searchParams.set('equipmentid', parseInt(equipmentid))
                    }
                }
            }
        }
        return url.searchParams
    }

    static removeWhiteSpace(name: any) {
        if (name !== undefined && name !== null && name !== "") {
            return name.trim();
        } else {
            return name;
        }
    }
    static updateDisplayStatus(status: any[], text: any, isReturnCode = false) {
        if (status !== undefined && status.length > 0) {
            if (isReturnCode) {
                return status.find((x: any) => x.status === text).shortStatus
            } else {
                return status.find((x: any) => x.status === text).displayStatus
            }
        }
    }
    static showDataResult(params: any, data: any, totalResult: any) {
        let startCount = 1;
        let endCount = 0;
        let totalCount = 0;
        startCount = startCount + (params.page - 1) * params.pageSize;
        endCount = params.pageSize > data.length ? params.page === 1 ? data.length : (startCount - 1) + data.length : (params.pageSize * params.page);
        totalCount = params.pageSize >= data.length ? totalResult : data.length;
        return { start: startCount, end: endCount, total: totalCount }
    }

    static setEmployerDetailFromAssignee(assignees: any) {
        let employers: any = [];
        assignees.forEach((item: any) => {
            if (!Utilities.isNull(item.employer) && employers.map((a: any) => a.name).indexOf(item.employer) === -1) {
                const employer = { id: item.id, name: item.employer, key: item.employer }
                employers.push(employer);
            }
        });
        employers = _.sortBy(employers, (a) => {
            if (Utilities.isEmpty(a.name.toLowerCase())) {
                return a;
            } else {
                return a.name.toLowerCase();
            }
        });

        if (assignees.some((x: any) => Utilities.isEmpty(x.providerEmployer))) {
            employers.splice(0, 0, { id: -1, name: DefaultValues.NoEmployer, key: DefaultValues.NoEmployer });
        }

        return employers;
    }

    static contentReplacer(dataReplacer: any, data: any) {
        Object.keys(dataReplacer).forEach((element: any) => {
            const re = new RegExp(element, "gi");
            data.content = data.content.replace(re, function (matched: any) {
                return dataReplacer[matched];
            });
            if (data.subHeaderContent !== undefined && data.subHeaderContent !== null && data.subHeaderContent !== '') {
                data.subHeaderContent = data.subHeaderContent.replace(re, function (matched: any) {
                    return dataReplacer[matched];
                });
            }
        });
        return data;
    }
    static difference(object: any, base: any) {

        return _.transform(object, (result: any, value: any[], key: any) => {
            if (value !== undefined && base !== undefined) {
                if (!_.isEqual(value, base[key])) {
                    result[key] =
                        _.isObject(value) && _.isObject(base[key])
                            ? _.difference(value, base[key])
                            : value;
                }

            }
        });
    }

    static convertNullToNA(data: any) {
        return new Promise((resolve, reject) => {
            data.forEach((element: any) => {
                Object.keys(element).forEach((key) => {
                    if (key === 'images') {
                        element[key].forEach((imgElement: any) => {
                            if (imgElement.isNewFile || imgElement.isUpdate || imgElement.isDeleted) {
                                delete imgElement['awsImageURL'];
                                delete imgElement['thumbnailAwsImageURL'];
                                delete imgElement['createdDateTime'];
                                delete imgElement['tempId'];
                                delete imgElement['floorId'];
                                delete imgElement['imageCheckCnt'];
                                delete imgElement['imgId'];
                                delete imgElement['isImageExsits'];
                                delete imgElement['isImageLoading'];
                                delete imgElement['updatedBy'];
                                delete imgElement['updatedByUser'];
                                delete imgElement['createdBy'];
                                delete imgElement['createdByUser'];
                            }
                        });
                    }
                    if (key === "id" && element[key] < 1 && element['tempId'] !== null && element['tempId'] !== undefined) {
                        element[key] = null
                    }
                    if (!ExcludeToConvertToNA.includes(key)) {
                        if (key === "childFloorEquipments") {
                            element[key] = this.convertChildNullToNA(element[key])
                        } else if (key === 'networkSwitchLinkId' && element[key] < 1) {
                            element[key] = -1;
                        } else if (element["id"] !== null) {
                            element = this.valueToNA(key, element);
                        }
                    }
                });
            });
            resolve(data)
        })
    }
    static employerChanged(filterForm: any, usersImmutable: any) {
        filterForm.controls['assignedToId'].setValue('');
        if (Utilities.isEmpty(filterForm.controls['employer'].value)) {
            const users = _.cloneDeep(usersImmutable);
            return users;
        } else if (filterForm.controls['employer'].value === DefaultValues.NoEmployer) {
            return usersImmutable.filter((x: any) => (x.employer === '' || x.employer === null) &&
                x.providerEmployer === '' || x.providerEmployer === null);
        } else {
            return usersImmutable.filter((x: any) => x.employer === filterForm.controls['employer'].value ||
                x.providerEmployer === filterForm.controls['employer'].value);
        }

    }
    static formatMACAddress(address: any) {
        const formattedValue = address.replace(/[^a-fA-F0-9]/g, '');
        if (formattedValue.length === 12) {
            const colonValue = formattedValue.replace(/(.{2})/g, "$1:").toString();
            return colonValue.slice(0, -1);
        } else {
            return address;
        }
    }
    static valueToNA(key: any, element: any) {
        if (key === "cableCoordinates" && element[key] !== null && element[key].length > 0 && typeof element[key] !== 'string') {
            element[key].shift();
            element[key].pop();
            element[key] = JSON.stringify(element[key]);
        } else if (element[key] === '' || element[key] === "" || element[key] === null || element[key] === undefined || element[key] === 'null') {
            if ((key === "height" || key === "price" || key === 'budgetedCost' || key === "installHours" || key === "installedByUser" || key === 'assignedToUser' || key === "fieldOfView")) {
                element[key] = -1
            } else if ((key === "networkSwitchLinkId" || key === "networkSwitchLinkFloorId") && (element[key] === undefined || element[key] === null || element[key] === 'null' || element[key] < 1)) {
                element[key] = -1
            } else if (key === "isFlagAdd" && (element[key] === null || element[key] === 'NA' || element[key] === '')) {
                element[key] = false
            } else if (key !== 'floorId') {
                if (key === 'cableCoordinates' && element['id'] && (element['id'] < 1 || (element['networkSwitchLinkId'] && element['networkSwitchLinkId'] < 1 && element['networkSwitchLinkId'] > -1)) && (element['cableCoordinates'] === null || element['cableCoordinates'].length === 0)) {
                    element[key] = null
                } else {
                    element[key] = "NA"
                }
            }
        }
        return element
    }

    static convertChildNullToNA(data: any) {
        data.forEach((element: any) => {
            Object.keys(element).forEach((key) => {
                if (key === 'images') {
                    element[key].forEach((imgElement: any) => {
                        if (imgElement.isNewFile || imgElement.isUpdate || imgElement.isDeleted) {
                            delete imgElement['awsImageURL'];
                            delete imgElement['thumbnailAwsImageURL'];
                            delete imgElement['createdDateTime'];
                            delete imgElement['tempId'];
                            delete imgElement['floorId'];
                            delete imgElement['imageCheckCnt'];
                            delete imgElement['imgId'];
                            delete imgElement['isImageExsits'];
                            delete imgElement['isImageLoading'];
                            delete imgElement['updatedBy'];
                            delete imgElement['updatedByUser'];
                            delete imgElement['createdBy'];
                            delete imgElement['createdByUser'];
                        }
                    });
                }
                if (key === "id" && element[key] < 1) {
                    element[key] = null
                }
                if (!ExcludeToConvertToNA.includes(key) && element["id"] !== null && key !== "floorId") {
                    element = this.valueToNA(key, element);
                }
                if (key === "networkSwitchLinkId" && (element[key] === undefined || element[key] === null || element[key] === 'null' || element[key] < 1)) {
                    element[key] = -1
                }
            });
        });
        return data;
    }


    static getCanvasBlob(format: ValidFormats, data: string): Promise<Blob> {
        return new Promise((resolve) => {
            let blob;
            if (data) {
                if (format === "json") {
                    blob = new Blob([data], { type: "application/json" });
                } else {
                    const contentType = "image/" + format;
                    data = data.replace(/data:image\/([a-z]*)?;base64,/, "");
                    blob = (b64toBlob as any)(data, contentType);
                }
                resolve(blob);
            }
        });
    }

    static setDownloadData(downloadData: any) {
        const systemTypeFilterArr: any = [];
        const deviceStatusFilterArr: any = [];
        const installStatusArr: any = [];
        const viewingPreference: any = [];
        if (downloadData) {
            if (downloadData.viewingPreference !== undefined) {
                downloadData.viewingPreference.forEach((element: any) => {
                    viewingPreference.push(element.category.key);
                });
            }
            if (downloadData.systemTypeFilter !== undefined) {
                downloadData.systemTypeFilter.forEach((element: any) => {
                    if (element.category.name === "Fire Alarm" || element.category.name === "Audio Visual" || element.category.name === "Infrastructure" ||
                        element.category.name === "Video Surveillance" || element.category.name === "Access Control" || element.category.name === "Intrusion Detection" ||
                        element.category.name === "Communications" || element.category.name === "Tasks") {
                        systemTypeFilterArr.push(element.category.id);
                    }
                })
            }
            if (downloadData.deviceStatus !== undefined) {
                downloadData.deviceStatus.forEach((element: any) => {
                    if (element.category.name === "OperationalWithIssue" || element.category.name === "NotWorking" || element.category.name === "Operational") {
                        deviceStatusFilterArr.push(element.category.name);
                    }
                })
            }
            if (downloadData.installStatus !== undefined) {
                downloadData.installStatus.forEach((element: any) => {
                    if (element.category.name === "Planned" || element.category.name === "InProgress" || element.category.name === "Installed") {
                        installStatusArr.push(element.category.name);
                    }
                })
            }
        }
        return { viewingPreference: viewingPreference, systemTypeFilterArr: systemTypeFilterArr, deviceStatusFilterArr: deviceStatusFilterArr, installStatusArr: installStatusArr }
    }

    static setFilterCategory(reportType: any, isProject: any, categoryData: any) {
        const filterCategory: any = [];
        ViewingReportPreferences.forEach((element: any) => {
            const category = new Category();
            category.id = element.id;
            category.name = element.name;
            category.displayName = element.displayName;
            category.key = element.key;
            if ((reportType === PrintOutType.NUMBERED || reportType === PrintOutType.MULTIPLAN) && (element.id === 3 || element.id === 4 || element.id === 5)) {
                filterCategory.push({ isChecked: true, category: category, type: 'vp' })
            } else if (reportType === PrintOutType.ICON && element.id !== 5 && element.id !== 8) {
                filterCategory.push({ isChecked: true, category: category, type: 'vp' })
            }
        })
        if (categoryData && categoryData.length > 0) {
            categoryData.forEach((category: Category) => {
                if (category.name !== 'Favorites' && category.name !== 'Tasks' && !isProject) {
                    filterCategory.push({ isChecked: true, category: category, type: 'st' })
                } else if (category.name !== 'Favorites' && isProject) {
                    filterCategory.push({ isChecked: true, category: category, type: 'st' })
                }
            });
        }
        OperationalStatusFilter.forEach((element: any) => {
            const category = new Category();
            category.id = element.id;
            category.name = element.name;
            category.displayName = element.displayName;
            filterCategory.push({ isChecked: true, category: category, type: 'op' })
        });
        if (isProject) {
            InstallStatusFilter.forEach((element: any) => {
                const category = new Category();
                category.id = element.id;
                category.name = element.name;
                category.displayName = element.displayName;
                filterCategory.push({ isChecked: true, category: category, type: 'is' })
            });
        }

        return filterCategory;
    }
}