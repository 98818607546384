/* eslint-disable @typescript-eslint/no-explicit-any */
import { Utilities } from '@SiteOwl/core';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TooltipModel } from '../tool-tip/tool-tip.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'so-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss'],
})
export class ViewImageComponent implements OnInit {
  equipment!: any;
  parentContainer!: any;
  imageSize!: any;
  imageZoomDefault = 100;
  imageZoom = 100;
  zoomValue = '100%';
  pixieLoader!: boolean;
  activeSlideIndex = 0;
  file: any;
  selectedImage: any;
  timer: any;
  updatedTime: any;
  createdTime: any;
  createdBy!: string;
  updatedBy!: string;
  toolTipObj: TooltipModel = new TooltipModel();
  viewImageLoadedSub!: Subscription;
  fitToScreen = true;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.pixieLoader = true;
    if (this.equipment && this.equipment.images) {
      this.equipment.images.forEach((element: any) => {
        if (element.awsImageURL === this.file.awsImageURL) {
          element.isShow = true
        } else {
          element.isShow = false
        }
        if (element.id === this.file.id) {
          this.selectedImage = element;
          this.preserveLogs(element);
        }
      });
    }
  }
  viewImageLoaded() {
    const timeOut = setTimeout(() => {
      this.onFloorLoadZoomCalculation();
      clearTimeout(timeOut);
    }, 100);
    this.showHideLoader(false);
  }
  private onFloorLoadZoomCalculation() {
    this.parentContainer = document.querySelector('#moveTicketParentContainer');
    this.imageSize = document.querySelector('#moveTicketFloorImageShadow');
    this.imageZoomDefault = _.cloneDeep(Math.min(Math.round(this.parentContainer.clientHeight * 100 / this.imageSize.naturalHeight), (this.parentContainer.clientWidth * 100 / this.imageSize.naturalWidth)));
    this.imageZoom = Math.min(Math.round(this.parentContainer.clientHeight * 100 / this.imageSize.naturalHeight), (this.parentContainer.clientWidth * 100 / this.imageSize.naturalWidth));
    this.getZoomWidthFromImage(this.imageZoom);
  }
  private getZoomWidthFromImage(data: any) {
    this.zoomValue = (data * this.imageSize.naturalWidth) / 100 + 'px';
  }
  getPreviousSlide(index: any) {
    const tmpIndex = index === 0 ? this.equipment.images.length - 1 : (index - 1);
    this.setSliderImage(tmpIndex);
  }
  getNextSlide(index: any) {
    const tmpIndex = index >= 0 && index === (this.equipment.images.length - 1) ? 0 : (index + 1);
    this.setSliderImage(tmpIndex);
  }
  setSliderImage(showIndex: any) {
    if (showIndex >= 0) {
      this.fitToScreen = true;
      this.showHideLoader(true);
      this.imageZoomDefault = 100;
      this.imageZoom = 100;
      this.zoomValue = '100%';
      this.activeSlideIndex = showIndex;

      this.equipment.images.forEach((element: any, index: any) => {
        if (index === showIndex) {
          element.isShow = true
        } else {
          element.isShow = false
        }
      });
      this.selectedImage = this.equipment.images[showIndex];
      this.preserveLogs(this.equipment.images[this.activeSlideIndex]);
      const img: any = document.querySelector('#moveTicketFloorImageShadow');
      if (showIndex >= this.equipment.images.length) {
        showIndex = 0
      }
      img.src = this.equipment.images[showIndex].awsImageURL;
    }
  }
  showHideLoader(loader: any) {
    this.pixieLoader = loader;
  }

  private preserveLogs(image: any) {
    this.createdTime = !Utilities.isEmpty(image.createdDateTime) ?
      moment(new Date(image.createdDateTime)).format('MM/DD/YYYY') : '';
    this.updatedTime = !Utilities.isEmpty(image.modifiedDateTime) ?
      moment(new Date(image.modifiedDateTime)).format('MM/DD/YYYY') : '';
    this.createdBy = image.createdBy || '';
    this.updatedBy = image.updatedBy || '';
  }

  closeModal() {
    this.bsModalRef.hide();
  }
  zoomChangeWithButtonCall(isZoom: any, fromHeader: any) {
    this.fitToScreen = false;
    if (isZoom) {
      if (this.imageZoom < 7) {
        this.imageZoom = 7
      }
      this.imageZoom = Math.round(this.imageZoom * 1.08);
    } else {
      this.imageZoom = Math.round(this.imageZoom / 1.08);
    }
    this.getZoomWidthFromImage(this.imageZoom);
  }
  zoomChangeCall() {
    this.imageZoom = _.cloneDeep(this.imageZoomDefault);
    this.getZoomWidthFromImage(this.imageZoom);
    this.fitToScreen = true;
  }

  fillToolTipObj(event: any, user: any) {
    this.timer = setTimeout(() => {
      this.toolTipObj.name = user.name;
      if (this.toolTipObj.name === null || this.toolTipObj.name === undefined) {
        this.toolTipObj.name = "(" + user.email + ")";
      }
      this.toolTipObj.employer = user.employer;
      this.toolTipObj.picture = user.thumbnailAwsImageURL;
      if (user.workNumber) {
        this.toolTipObj.work = user.workNumber;
      }
      if (user.cellNumber) {
        this.toolTipObj.mobile = user.cellNumber;
      }
      this.toolTipObj.email = user.email;
    }, 300);
    this.toolTipObj = new TooltipModel();

  }

  out() {
    clearTimeout(this.timer)
  }
  imageZoomChanged(event: any) {
    
    this.zoomChangeWithButtonCall(event,false);
  }
  imageDragged(event: any) {
    this.fitToScreen = event;
  }
}
